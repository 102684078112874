import { Input, Spinner, Textarea } from "@material-tailwind/react";
import { useState } from "react";

const ContactForm = () => {
  const [fullName, setFullName] = useState("");
  const [theEmail, setTheEmail] = useState("");
  const [thePhone, setThePhone] = useState("");
  const [theMessage, setTheMessage] = useState("");
  const [error, setError] = useState(false);
  const [isBusy, setIsBusy] = useState(false);

  //handleContactSubmit
  const handleContactSubmit = () => {
    if (!fullName || !theEmail || !thePhone || !theMessage) {
      setError(true);
    } else {
      setError(false);
      setIsBusy(true);
      const emailSubject = "Support Request";
      const emailBody = `
              Full Name: ${fullName}
              Email: ${theEmail}
              Phone: ${thePhone}
              Message: ${theMessage}
          `;
      // support@yarp.co

      const mailtoLink = `mailto:support@yarp.co?subject=${encodeURIComponent(
        emailSubject
      )}&body=${encodeURIComponent(emailBody)}`;

      setIsBusy(false);

      window.location.href = mailtoLink;
    }
  };

  return (
    <>
      <div className="p-[60px] rounded-[20px] bg-white shadow-lg">
        <p className="text-[28px] font-[600] leading-[35px] text-yarpGray">
          Send us a Message
        </p>

        <div className="mt-[32px]">
          <p className="text-[12px] leading-[20px] font-[400] text-yarpGray">
            Full Name*
          </p>
          <Input
            crossOrigin=""
            variant="static"
            placeholder="John Doe"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            error={error}
          />
        </div>

        <div className="mt-[25px]">
          <p className="text-[12px] leading-[20px] font-[400] text-yarpGray">
            Email*
          </p>
          <Input
            type="email"
            crossOrigin=""
            variant="static"
            placeholder="example@yourmail.com"
            value={theEmail}
            onChange={(e) => setTheEmail(e.target.value)}
            error={error}
          />
        </div>

        <div className="mt-[25px]">
          <p className="text-[12px] leading-[20px] font-[400] text-yarpGray">
            Phone*
          </p>
          <Input
            crossOrigin=""
            variant="static"
            placeholder="+233 000 00 0000"
            value={thePhone}
            onChange={(e) => setThePhone(e.target.value)}
            error={error}
          />
        </div>

        <div className="mt-[25px]">
          <p className="text-[12px] leading-[20px] font-[400] text-yarpGray">
            Message*
          </p>
          <Textarea
            variant="static"
            placeholder="type your message here"
            value={theMessage}
            onChange={(e) => setTheMessage(e.target.value)}
            error={error}
          />
        </div>

        <div className="mt-[25px] flex justify-end">
          <button
            className="w-[120px] py-2 rounded-[30px] bg-yarpOrange text-white flex justify-center items-center disabled:bg-gray-400 disabled:cursor-not-allowed"
            onClick={() => handleContactSubmit()}
            disabled={isBusy}
          >
            {isBusy ? <Spinner /> : "Send"}
          </button>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
