import { Input, Textarea } from "@material-tailwind/react";
import PageTitileTag from "../../components/general/page-title-tag";
import CustomFooter from "../../components/layout/custom-footer";
import CustomNavbar from "../../components/layout/custom-navbar";
import bgImg from "../../images/contactMap.png";
import {
  DevicePhoneMobileIcon,
  EnvelopeIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";
import ContactForm from "./contact-form";
import ContactMap from "./contact-map";

const ContactPage = () => {
  return (
    <>
      <div className="h-screen overflow-hidden">
        <CustomNavbar contactActive="active" />

        <div className="h-full overflow-y-auto w-full relative">
          <div className="relative">
            <ContactMap />

            {/* <div className="w-[300px] h-fit absolute bottom-[70px] lg:left-[90px] 2xl:left-[300px]">
              <PageTitileTag pageTitle="Contact" />
              <p className="mt-[25px] font-[600] text-[35px] leading-[40px] text-yarpGray">
                Let’s talk about your problem.
              </p>
            </div> */}
          </div>

          <div className="lg:h-[650px] lg:grid lg:grid-cols-3 relative">
            {/* left */}
            <div className="col-span-2 lg:pl-[100px] 2xl:pl-[300px] pl-5 pt-[100px]">
              <div className="lg:grid lg:grid-cols-2 gap-10 2xl:w-full lg:w-[500px] md:w-[300px]">
                <div className="flex gap-[28px]">
                  <MapPinIcon className="w-[27px] h-[27px] text-yarpOrange" />
                  <div>
                    <p className="text-[18px] font-[600] leading-[24px] text-yarpGray">
                      Our Location
                    </p>
                    <p className="text-[14px] font-[400] leading-[21px] text-yarpGray mt-[18px]">
                      No. 26 Kofi Annan Avenue, North Legon - Accra, Ghana
                    </p>
                  </div>
                </div>

                <div className="flex gap-[28px]">
                  <EnvelopeIcon className="w-[27px] h-[27px] text-yarpOrange" />
                  <div>
                    <p className="text-[18px] font-[600] leading-[24px] text-yarpGray">
                      How Can We Help?
                    </p>
                    <p className="text-[14px] font-[400] leading-[21px] text-yarpGray mt-[18px]">
                      info@yarp.co <br />
                      support@yarp.co
                    </p>
                  </div>
                </div>

                <div className="flex gap-[28px]">
                  <DevicePhoneMobileIcon className="w-[27px] h-[27px] text-yarpOrange" />
                  <div>
                    <p className="text-[18px] font-[600] leading-[24px] text-yarpGray">
                      Phone Number
                    </p>
                    <p className="text-[14px] font-[400] leading-[21px] text-yarpGray mt-[18px]">
                      +233 (0) 25 723 8313
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* right */}
            <div className="col-span-1 lg:absolute lg:right-[100px] right-[40px] lg:w-[470px] lg:bottom-[200px] mt-10 lg:mt-0">
              <ContactForm />
            </div>
          </div>

          <div>
            <CustomFooter />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactPage;
