import { useState } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import PageTitileTag from "../../../components/general/page-title-tag";

const FaqsComponent = () => {
  const [open, setOpen] = useState(1);

  const handleOpen = (value: any) => setOpen(open === value ? 0 : value);

  return (
    <>
      <div className="flex justify-center">
        <PageTitileTag pageTitle="FAQs" />
      </div>
      <div className="flex justify-center w-full mt-[20px]">
        <p className="text-yarpGray lg:text-[42px] text-[28px] lg:leading-[63px] font-[600] mt-[20px] lg:w-[833px] text-center">
          Frequently asked questions
        </p>
      </div>
      <div className="lg:mt-[80px] mt-[40px]">
        <Accordion
          open={open === 1}
          className="px-[40px] py-[20px] bg-white shadow-sm"
        >
          <AccordionHeader className="border-b-0">
            <div className="flex justify-between items-center w-full">
              <p className="font-[600] lg:text-[26px] text-[16px] lg:leading-[39px] text-yarpGray">
                Can I use yarp Pro for my clients projects?
              </p>
              {open === 1 ? (
                <MinusCircleIcon
                  className="w-[35px] h-[35px] text-yarpOrange cursor-pointer"
                  onClick={() => handleOpen(1)}
                />
              ) : (
                <PlusCircleIcon
                  className="w-[35px] h-[35px] text-yarpOrange cursor-pointer"
                  onClick={() => handleOpen(1)}
                />
              )}
            </div>
          </AccordionHeader>
          <AccordionBody>
            We&apos;re not always in the position that we want to be at.
            We&apos;re constantly growing. We&apos;re constantly making
            mistakes. We&apos;re constantly trying to express ourselves and
            actualize our dreams.
          </AccordionBody>
        </Accordion>

        <Accordion
          open={open === 2}
          className="px-[40px] py-[20px] bg-white shadow-sm mt-[10px]"
        >
          <AccordionHeader className="border-b-0">
            <div className="flex justify-between items-center w-full">
              <p className="font-[600] lg:text-[26px] text-[16px] lg:leading-[39px] text-yarpGray">
                Which license type is suitable for me?
              </p>
              {open === 2 ? (
                <MinusCircleIcon
                  className="w-[35px] h-[35px] text-yarpOrange cursor-pointer"
                  onClick={() => handleOpen(2)}
                />
              ) : (
                <PlusCircleIcon
                  className="w-[35px] h-[35px] text-yarpOrange cursor-pointer"
                  onClick={() => handleOpen(2)}
                />
              )}
            </div>
          </AccordionHeader>
          <AccordionBody>
            We&apos;re not always in the position that we want to be at.
            We&apos;re constantly growing. We&apos;re constantly making
            mistakes. We&apos;re constantly trying to express ourselves and
            actualize our dreams.
          </AccordionBody>
        </Accordion>

        <Accordion
          open={open === 3}
          className="px-[40px] py-[20px] bg-white shadow-sm mt-[10px]"
        >
          <AccordionHeader className="border-b-0">
            <div className="flex justify-between items-center w-full">
              <p className="font-[600] lg:text-[26px] text-[16px] lg:leading-[39px] text-yarpGray">
                Is Windy UI Well-documented?
              </p>
              {open === 3 ? (
                <MinusCircleIcon
                  className="w-[35px] h-[35px] text-yarpOrange cursor-pointer"
                  onClick={() => handleOpen(3)}
                />
              ) : (
                <PlusCircleIcon
                  className="w-[35px] h-[35px] text-yarpOrange cursor-pointer"
                  onClick={() => handleOpen(3)}
                />
              )}
            </div>
          </AccordionHeader>
          <AccordionBody>
            We&apos;re not always in the position that we want to be at.
            We&apos;re constantly growing. We&apos;re constantly making
            mistakes. We&apos;re constantly trying to express ourselves and
            actualize our dreams.
          </AccordionBody>
        </Accordion>

        <Accordion
          open={open === 4}
          className="px-[40px] py-[20px] bg-white shadow-sm mt-[10px]"
        >
          <AccordionHeader className="border-b-0">
            <div className="flex justify-between items-center w-full">
              <p className="font-[600] lg:text-[26px] text-[16px] lg:leading-[39px] text-yarpGray">
                Do you provide support?
              </p>
              {open === 4 ? (
                <MinusCircleIcon
                  className="w-[35px] h-[35px] text-yarpOrange cursor-pointer"
                  onClick={() => handleOpen(4)}
                />
              ) : (
                <PlusCircleIcon
                  className="w-[35px] h-[35px] text-yarpOrange cursor-pointer"
                  onClick={() => handleOpen(4)}
                />
              )}
            </div>
          </AccordionHeader>
          <AccordionBody>
            We&apos;re not always in the position that we want to be at.
            We&apos;re constantly growing. We&apos;re constantly making
            mistakes. We&apos;re constantly trying to express ourselves and
            actualize our dreams.
          </AccordionBody>
        </Accordion>
      </div>
    </>
  );
};

export default FaqsComponent;
