import earnimg from "../../../images/earnImg.svg";
import DownloadAppComponent from "../../../components/general/downloadApp/downloadAppComponent";

const DownloadNowComponent = () => {
  return (
    <>
      <div className="2xl:h-[353px] bg-yarpOrange rounded-[60px] 2xl:px-[100px] lg:px-[50px] px-5">
        <div className="lg:grid lg:grid-cols-2 lg:gap-x-[42px]">
          <div className="py-[52px]">
            <p className="lg:text-[42px] text-[32px] font-[600] lg:leading-[63px] leading-[40px] text-white">
              Earn more with yarp
            </p>
            <p className="lg:text-[20px] text-[14px] font-[400] leading-[32px] text-white">
              Always know when and where to pick up your next load. Start
              earning more with every mile you drive.
            </p>

            <div className="mt-[42px]">
              <DownloadAppComponent />
            </div>
          </div>

          <div className="hidden lg:block relative">
            <div className="absolute 2xl:top-[31px] lg:bottom-0 2xl:right-24 lg:right-5">
              <img src={earnimg} alt="earn" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DownloadNowComponent;
