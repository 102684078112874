import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/home-page/home-page";
import DriverPage from "./pages/driver-page/driver-page";
import FleetOwnerPage from "./pages/fleetowner-page/fleetowner-page";
import ContactPage from "./pages/contact-page/contact-page";
import PrivacyPolicy from "./pages/privacy-policy/privacyPolicy";
import TermsOfService from "./pages/terms-of-service/terms-of-service";
import AboutPage from "./pages/about-page/about-us";
import DriverSupport from "./pages/support/driverSupport";
import InsureancePage from "./pages/insurance/insureancePage";
import SupportPage from "./pages/support/support";
import UserSupport from "./pages/support/userSupport";
import StreamliningPage from "./pages/home-page/components/StreamliningPage";
import AIPoweredLogistics from "./pages/home-page/components/AIPoweredLogistics";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/home/streamlining_last_mile_delivery"
            element={<StreamliningPage />}
          />
          <Route
            path="/home/ai_powered_logistics"
            element={<AIPoweredLogistics />}
          />
          <Route path="/driver" element={<DriverPage />} />
          <Route path="/fleet-owner" element={<FleetOwnerPage />} />
          <Route path="/contact-us" element={<ContactPage />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/support" element={<SupportPage />} />
          <Route path="/insurance" element={<InsureancePage />} />
          <Route path="/driver-support" element={<DriverSupport />} />
          <Route path="/user-support" element={<UserSupport />} />
        </Routes>
      </Router>
    </>
  );
}
export default App;
