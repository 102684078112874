import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import CustomFooter from "../../components/layout/custom-footer";
import { Breadcrumbs } from "@material-tailwind/react";
import SearchComponent from "../../components/general/searchComponent";

const SupportPage = () => {
  const navigate = useNavigate();

  const tabNames = [
    {
      name: "User Support",
      link: "/user-support",
    },
    {
      name: "Driver Support",
      link: "/driver-support",
    },
    // {
    //   name: "Fleet Owner Support",
    //   link: "/fleet-support",
    // },
  ];

  return (
    <>
      <div className="h-screen overflow-auto grid grid-rows-2">
        <div className="row-span-1 bg-signupbg relative h-full w-full p-[35px]">
          <div className="absolute h-full w-full bg-black bottom-0 left-0 bg-opacity-[50%]"></div>
          <div className="relative z-10">
            <button className="h-10 w-10" onClick={() => navigate("/")}>
              <ChevronLeftIcon className="cursor-pointer text-white h-5 w-5" />
            </button>
            <div className="flex items-center h-full text-white flex-col">
              <p className="font-semibold text-2xl">yarp:</p>
              <p className="font-[400] text-[36px] mt-[35px] text-center">
                Having Some difficulties?
              </p>
              <p className="font-[300] text-[14px] mt-[10px]">
                We're just a click away
              </p>
            </div>
          </div>
          {/* <div className="mt-[30px] flex justify-center">
            <SearchComponent />
          </div> */}
        </div>

        <div className="h-full bg-[#fffcf9]">
          <div className="mt-[35px] mb-[200px] 2xl:px-[100px] lg:px-[50px] px-5">
            <div>
              <Breadcrumbs>
                <Link to="/" className="text-[14px] font-400 text-yarpGray">
                  Yarp
                </Link>
                <Link to="#" className="text-[14px] font-400 text-yarpGray">
                  Yarp Support
                </Link>
              </Breadcrumbs>

              <div className="mt-[35px] lg:grid 2xl:grid-cols-4 lg:grid-cols-3 w-full gap-x-[32px]">
                {tabNames.map((item, i) => (
                  <div
                    key={i}
                    className="w-full bg-yarpOrange rounded-[20px] h-[145px] text-white flex justify-center items-center cursor-pointer mb-5 lg:mb-0 hover:scale-105 ease-in-out delay-100"
                    onClick={() => navigate(item.link)}
                  >
                    <p className="text-[26px] font-[600]">{item?.name}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <CustomFooter />
        </div>
      </div>
    </>
  );
};

export default SupportPage;
