import mapImg from "../../../images/mapImg.png";

const OperateComponent = () => {
  return (
    <>
      <div className="w-full bg-yarpBlue py-[100px]">
        <div className="flex justify-center">
          <div className="w-fit py-[4px] border-[1px] border-white rounded-[40px] text-white px-[14px]">
            Where we operate
          </div>
        </div>
        <div className="flex justify-center w-full mt-[20px]">
          <p className="text-white lg:text-[42px] text-[28px] leading-[63px] font-[600] mt-[20px] lg:w-[833px] text-center">
            Reaching the Globe
          </p>
        </div>
        <div className="flex justify-center w-full mt-[20px]">
          <p className="text-white lg:text-[20px] text-[14px] leading-[32px] font-[400] mt-[20px] lg:w-[833px] text-center">
            YARP: Your Assurance for Reliable Performance
          </p>
        </div>

        <div className="mt-[80px] flex justify-center items-center">
          <img className="lg:w-[800px]" src={mapImg} alt="gh" />
        </div>
      </div>
    </>
  );
};

export default OperateComponent;
