import { useState } from "react";
import { Link } from "react-router-dom";
import earnimg from "../../../images/earnImg.svg";
import fleetImg from "../../../images/fleetLap.png";
import goodsImg from "../../../images/goodsImg.svg";
import DownloadAppComponent from "../../../components/general/downloadApp/downloadAppComponent";

//goods owners
const GoodsOwners = () => {
  return (
    <>
      <div className="2xl:h-[353px] bg-yarpOrange rounded-[60px] 2xl:px-[100px] lg:px-[50px] px-5">
        <div className="lg:grid lg:grid-cols-2 lg:gap-x-[42px]">
          <div className="py-[52px]">
            <p className="lg:text-[42px] text-[32px] font-[600] lg:leading-[63px] leading-[40px] text-white">
              Grow your business
            </p>
            <p className="lg:text-[20px] text-[14px] font-[400] leading-[32px] text-white">
              Tap into new customer segments, and increase sales opportunities.
            </p>

            <div className="mt-[42px]">
              <DownloadAppComponent />
            </div>
          </div>

          <div className="hidden lg:block relative">
            <div className="absolute 2xl:top-[31px] lg:bottom-0 2xl:right-24 lg:right-5">
              <img src={goodsImg} alt="good" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

//earn more
const EarnMore = () => {
  return (
    <>
      <div className="2xl:h-[353px] bg-yarpOrange rounded-[60px] 2xl:px-[100px] lg:px-[50px] px-5">
        <div className="lg:grid lg:grid-cols-2 lg:gap-x-[42px]">
          <div className="py-[52px]">
            <p className="lg:text-[42px] text-[32px] font-[600] lg:leading-[63px] leading-[40px] text-white">
              Earn more with yarp
            </p>
            <p className="lg:text-[20px] text-[14px] font-[400] leading-[32px] text-white">
              Always know when and where to pick up your next load. Start
              earning more with every mile you drive.
            </p>

            <div className="mt-[42px]">
              <DownloadAppComponent />
            </div>
          </div>

          <div className="hidden lg:block relative">
            <div className="absolute 2xl:top-[31px] lg:bottom-0 2xl:right-24 lg:right-5">
              <img src={earnimg} alt="earn" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

//fleet
const Fleet = () => {
  return (
    <>
      <div className="2xl:h-[353px] bg-yarpOrange rounded-[60px] 2xl:px-[100px] lg:px-[50px] px-5">
        <div className="lg:grid lg:grid-cols-2 lg:gap-x-[42px]">
          <div className="py-[70px]">
            <p className="lg:text-[42px] text-[32px] font-[600] lg:leading-[63px] leading-[40px] text-white">
              Empowering Fleet Owners
            </p>
            <p className="lg:text-[20px] text-[14px] font-[400] leading-[32px] text-white">
              Take command of your fleet's potential with Yarp.
            </p>

            <div className="mt-[42px] flex gap-[20px]">
              <Link
                to="https://fleet.yarp.co/"
                target="_blank"
                className="h-[40px] w-[200px] border border-white text-white flex justify-center items-center rounded-full"
              >
                Sign In
              </Link>
              <Link
                to="https://fleet.yarp.co/signup"
                target="_blank"
                className="h-[40px] w-[200px] bg-white text-yarpOrange flex justify-center items-center rounded-full"
              >
                Sign Up
              </Link>
            </div>
          </div>

          <div className="hidden lg:block relative">
            <div className="absolute 2xl:top-[70px] lg:top-20 2xl:right-24">
              <img src={fleetImg} alt="earn" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const DownloadNowComponent = () => {
  const [activeTab, setActiveTab] = useState(0);
  const tabs = ["Goods Owners", "Driver", "Fleet Owner"];

  const tabComponents = [<GoodsOwners />, <EarnMore />, <Fleet />];

  return (
    <>
      <div className="flex gap-[20px] justify-center items-center">
        {tabs.map((dd, i) => (
          <div
            key={i}
            className={`${
              activeTab === i
                ? "bg-yarpOrange text-white "
                : "border-[2px] border-yarpGray text-yarpGray"
            } px-[29px] py-[8px] rounded-[40px] cursor-pointer`}
            onClick={() => setActiveTab(i)}
          >
            <p className="font-[600] lg:text-[14px] text-[12px] lg:leading-[27px]">
              {dd}
            </p>
          </div>
        ))}
      </div>

      <div className="mt-[72px]">{tabComponents[activeTab]}</div>
    </>
  );
};

export default DownloadNowComponent;
