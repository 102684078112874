import appleImg from "../../../images/apple-white.svg";
import playImg from "../../../images/playstore-white.svg";
import { Menu, MenuHandler, MenuList } from "@material-tailwind/react";
import { Link } from "react-router-dom";

const DownloadAppComponent = () => {
  return (
    <>
      <div className="flex gap-[20px]">
        {/* apple */}
        <Menu placement="top-start">
          <MenuHandler>
            <button className="h-[60px] lg:w-[200px] w-[150px]">
              <img src={appleImg} alt="apple" className="w-full" />
            </button>
          </MenuHandler>
          <MenuList className="p-5 flex flex-col gap-y-3">
            <Link
              to="https://apps.apple.com/gh/app/yarp-move-goods-everywhere/id6468881078"
              target="_blank"
              className="w-full px-5 py-2 rounded-[40px] bg-yarpOrange text-white outline-none"
            >
              Download Yarp User
            </Link>

            <Link
              to="https://apps.apple.com/gh/app/yarp-driver-drive-earn-more/id6468880490"
              target="_blank"
              className="w-full px-5 py-2 rounded-[40px] bg-yarpBlue text-white outline-none"
            >
              Download Yarp Driver
            </Link>
          </MenuList>
        </Menu>

        {/* android */}
        <Menu placement="top-start">
          <MenuHandler>
            <button className="h-[60px] lg:w-[200px] w-[150px]">
              <img src={playImg} alt="play" className="w-full" />
            </button>
          </MenuHandler>
          <MenuList className="p-5 flex flex-col gap-y-3">
            <Link
              to="https://play.google.com/store/apps/details?id=com.yarp.yarp"
              target="_blank"
              className="w-full px-5 py-2 rounded-[40px] bg-yarpOrange text-white outline-none"
            >
              Download Yarp User
            </Link>

            <Link
              to="https://play.google.com/store/apps/details?id=com.yarp.yarpdriver"
              target="_blank"
              className="w-full px-5 py-2 rounded-[40px] bg-yarpBlue text-white outline-none"
            >
              Download Yarp Driver
            </Link>
          </MenuList>
        </Menu>
      </div>
    </>
  );
};

export default DownloadAppComponent;
