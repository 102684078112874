import { Link } from "react-router-dom";
import halfLappy from "../../../images/laptopImg.png";

const TopBanner = () => {
  return (
    <>
      <div className="pb-4 pl-4 lg:pl-[100px] 2xl:pl-[300px] h-[720px] bg-secondaryBG">
        <div className="lg:grid lg:grid-cols-2 lg:gap-x-[65px]">
          {/* left */}
          <div className="flex flex-col h-full lg:mt-[80px] mt-[20px]">
            <div className="lg:w-[564px]">
              <p className="font-[600] text-[40px] lg:text-[60px] text-yarpGray lg:leading-[74px] leading-[50px]">
                Empowering Fleet Owners
              </p>

              <div className="lg:mt-[40px] mt-[30px]">
                <p className="lg:text-[20px] text-[18px] font-[400] lg:leading-[32px] leading-[28px] text-yarpGray">
                  Take command of your fleet's potential with Yarp. From
                  real-time tracking to resource optimization, experience
                  increased operational efficiency, reduced costs, and amplified
                  growth opportunities. Elevate your fleet management game
                  today."
                </p>
              </div>

              <div className="lg:mt-[70px] mt-[50px] flex gap-[18px] px-5 lg:px-0">
                <Link
                  to="https://fleet.yarp.co"
                  target="_blank"
                  className="px-[36px] py-[10px] rounded-[40px] border-[1px] border-yarpOrange text-yarpOrange lg:w-[254px] flex justify-center"
                >
                  Sign In
                </Link>
                <Link
                  to="https://fleet.yarp.co/signup"
                  target="_blank"
                  className="px-[36px] py-[10px] rounded-[40px] bg-yarpOrange text-white lg:w-[254px] flex justify-center"
                >
                  Register
                </Link>
              </div>
            </div>
          </div>

          {/* right */}
          <div className="flex items-center justify-end mt-5 lg:mt-10">
            <img src={halfLappy} alt="truck" className="w-[700px]" />
          </div>
        </div>
      </div>
    </>
  );
};

export default TopBanner;
