import yieldIco from "../../../images/yieldIco.svg";
import centricIco from "../../../images/centricIco.svg";
import relyIco from "../../../images/relyIco.svg";
import accIco from "../../../images/accIco.svg";

const CoreValues = () => {
  //contents
  const tabContents = [
    {
      image: <img src={yieldIco} alt="yield" />,
      title: (
        <p className="text-[26px] font-[600] leading-[39px] text-yarpGray">
          Yielding to Excellence
        </p>
      ),
      content: (
        <p className="text-[14px] font-[400] leading-[21px] text-yarpGray">
          We are committed to achieving excellence in everything we do. We
          strive to deliver exceptional performance, constantly improving our
          services and solutions to meet and exceed customer expectations.
        </p>
      ),
    },
    {
      image: <img src={accIco} alt="acc" />,
      title: (
        <p className="text-[26px] font-[600] leading-[39px] text-yarpGray">
          Accountability
        </p>
      ),
      content: (
        <p className="text-[14px] font-[400] leading-[21px] text-yarpGray">
          We take ownership of our actions and decisions. We are accountable for
          the quality of our services, the trust our customers place in us, and
          the impact we have on the logistics industry. We honor our commitments
          and take responsibility for delivering reliable solutions.
        </p>
      ),
    },
    {
      image: <img src={centricIco} alt="centric" />,
      title: (
        <p className="text-[26px] font-[600] leading-[39px] text-yarpGray">
          People-Centric Approach
        </p>
      ),
      content: (
        <p className="text-[14px] font-[400] leading-[21px] text-yarpGray">
          We prioritize the safety, well-being, and satisfaction of our
          customers, drivers, and employees. Our People-Centric Approach fosters
          strong relationships, collaboration, and a positive impact in
          logistics.
        </p>
      ),
    },
    {
      image: <img src={relyIco} alt="rely" />,
      title: (
        <p className="text-[26px] font-[600] leading-[39px] text-yarpGray">
          Reliability
        </p>
      ),
      content: (
        <p className="text-[14px] font-[400] leading-[21px] text-yarpGray">
          We prioritize reliability in every aspect of our operations. From
          ensuring timely deliveries to providing accurate information and
          dependable support, we are dedicated to being a trusted partner for
          our customers, drivers, and stakeholders.
        </p>
      ),
    },
  ];

  return (
    <>
      <div className="lg:grid lg:grid-cols-4 lg:gap-[16px] 2xl:mt-[80px] lg:mt-[80px] mt-[40px]">
        {tabContents.map((item, i) => (
          <div
            key={i}
            className="px-[30px] py-[40px] rounded-[20px] shadow-md bg-white w-full mb-4 lg:mb-0"
          >
            {item?.image}
            <div className="mt-[20px]">{item?.title}</div>
            <div className="mt-[20px]">{item?.content}</div>
          </div>
        ))}
      </div>
    </>
  );
};

export default CoreValues;
