import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { Rating } from "@material-tailwind/react";
import { useRef } from "react";
import Slider from "react-slick";
import williamImg from "../../../images/williamImg.png";
import mensahImg from "../../../images/mensahImg.png";
import mikeImg from "../../../images/mikeImg.png";
import jamesImg from "../../../images/james.png";

const TestimonyComponent = () => {
  const sliderRef: any = useRef();

  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const next = () => {
    sliderRef.current.slickNext();
  };
  const previous = () => {
    sliderRef.current.slickPrev();
  };

  //testimonyList
  const testimonyList = [
    {
      name: <p className="font-[600] text-[14px] leading-[21px]">William S.</p>,
      title: (
        <p className="font-[400] text-[12px] leading-[16px] text-yarpGray mt-[5px]">
          Logistics Driver
        </p>
      ),
      image: <img src={williamImg} alt="william" />,
      testimony: (
        <p className="mt-[22px] text-[14px] leading-[21px] font-[400] text-yarpGray lg:line-clamp-2">
          “Reliable GPS tracking and great customer support make this app a
          winner."
        </p>
      ),
    },
    {
      name: <p className="font-[600] text-[14px] leading-[21px]">Mensah F. </p>,
      title: (
        <p className="font-[400] text-[12px] leading-[16px] text-yarpGray mt-[5px]">
          Logistics Manager
        </p>
      ),
      image: <img src={mensahImg} alt="mensah" />,
      testimony: (
        <p className="mt-[22px] text-[14px] leading-[21px] font-[400] text-yarpGray lg:line-clamp-2">
          “This app streamlines our supply chain with user-friendly features
          like real-time tracking and instant communication
        </p>
      ),
    },
    {
      name: <p className="font-[600] text-[14px] leading-[21px]">Mike D. </p>,
      title: (
        <p className="font-[400] text-[12px] leading-[16px] text-yarpGray mt-[5px]">
          Driver
        </p>
      ),
      image: <img src={mikeImg} alt="mike" />,
      testimony: (
        <p className="mt-[22px] text-[14px] leading-[21px] font-[400] text-yarpGray lg:line-clamp-2">
          “The app simplifies my job, making scheduling, route updates, and
          proof of delivery easy, boosting efficiency"
        </p>
      ),
    },
    {
      name: <p className="font-[600] text-[14px] leading-[21px]">James A.</p>,
      title: (
        <p className="font-[400] text-[12px] leading-[16px] text-yarpGray mt-[5px]">
          Retailer
        </p>
      ),
      image: <img src={jamesImg} alt="james" />,
      testimony: (
        <p className="mt-[22px] text-[14px] leading-[21px] font-[400] text-yarpGray lg:line-clamp-2">
          "The app saves time and money by optimizing inventory and orders,
          reducing errors, and boosting profitability."
        </p>
      ),
    },
  ];

  return (
    <>
      <div className="mt-[70px]">
        <Slider ref={sliderRef} {...settings}>
          {testimonyList.map((item, i) => (
            <div
              key={i}
              className="h-auto p-[30px] bg-white rounded-[20px] shadow-md mb-5 gap-5"
            >
              <div>
                <Rating value={5} readonly />

                {item?.testimony}

                <div className="mt-[22px] flex gap-[15px] items-center">
                  {item?.image}
                  <div>
                    {item?.name}
                    {item?.title}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>

        {/* arrow buttons */}
        <div className="flex justify-center lg:mt-[72px] mt-[30px]">
          <div className="h-[63px] w-[115px] bg-white shadow-lg rounded-[40px] flex gap-[6px] justify-center items-center">
            <button
              className="h-[48px] w-[48px] rounded-full hover:bg-yarpOrange hover:text-white text-yarpOrange flex justify-center items-center"
              onClick={previous}
            >
              <ArrowLeftIcon className="h-5 w-5" />
            </button>
            <button
              className="h-[48px] w-[48px] rounded-full hover:bg-yarpOrange hover:text-white text-yarpOrange flex justify-center items-center"
              onClick={next}
            >
              <ArrowRightIcon className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestimonyComponent;
