import PageTitileTag from "../../components/general/page-title-tag";
import CustomFooter from "../../components/layout/custom-footer";
import CustomNavbar from "../../components/layout/custom-navbar";
import fleetImg from "../../images/fleetLap.png";
import FeaturesComponent from "./components/features-component";
import HowItWorks from "./components/how-it-works";
import TopBanner from "./components/top-banner";
import SignUpForm from "./components/sign-up-form";
import { Link } from "react-router-dom";

const FleetOwnerPage = () => {
  return (
    <>
      <div className="h-screen overflow-hidden">
        <CustomNavbar fleetActive="active" />

        <div className="h-full overflow-y-auto">
          <TopBanner />

          {/* features */}
          <div className="mt-[50px] lg:mt-[100px] lg:px-[118px] 2xl:px-[300px] px-5">
            <div className="flex justify-center">
              <PageTitileTag pageTitle="Features" />
            </div>
            <p className="text-yarpGray lg:text-[32px] text-[28px] leading-[63px] font-[600] mt-[20px] text-center">
              Get the Best for being a Fleet owner
            </p>
            <p className="text-yarpGray lg:text-[20px] text-[16px] leading-[32px] font-[400] mt-[20px] text-center">
              Completely synergize resource taxing relationships via premier
              niche markets. Professionally cultivate customer service.
            </p>

            <FeaturesComponent />
          </div>

          {/* join us */}
          <div className="mt-[50px] lg:mt-[100px] lg:px-[50px] 2xl:px-[300px] px-5">
            <div className="flex justify-center">
              <PageTitileTag pageTitle="Join Us" />
            </div>
            <p className="text-yarpGray lg:text-[32px] text-[28px] leading-[63px] font-[600] mt-[20px] text-center">
              How It works
            </p>

            <HowItWorks />
          </div>

          {/* sign up section */}
          {/* <div className="mt-[50px] lg:mt-[100px] bg-signupbg h-[853px] w-full bg-no-repeat">
            <SignUpForm />
          </div> */}

          <div className="lg:px-[100px] 2xl:px-[300px] px-5 mt-[100px] mb-[90px]">
            <div className="2xl:h-[353px] bg-yarpOrange rounded-[60px] 2xl:px-[100px] lg:px-[50px] px-5">
              <div className="lg:grid lg:grid-cols-2 lg:gap-x-[42px]">
                <div className="py-[70px]">
                  <p className="lg:text-[42px] text-[32px] font-[600] lg:leading-[63px] leading-[40px] text-white">
                    Empowering Fleet Owners
                  </p>
                  <p className="lg:text-[20px] text-[14px] font-[400] leading-[32px] text-white">
                    Take command of your fleet's potential with Yarp.
                  </p>

                  <div className="mt-[42px] flex gap-[20px]">
                    <Link
                      to="https://fleet.yarp.co/"
                      target="_blank"
                      className="h-[40px] w-[200px] border border-white text-white flex justify-center items-center rounded-full"
                    >
                      Sign In
                    </Link>
                    <Link
                      to="https://fleet.yarp.co/signup"
                      target="_blank"
                      className="h-[40px] w-[200px] bg-white text-yarpOrange flex justify-center items-center rounded-full"
                    >
                      Sign Up
                    </Link>
                  </div>
                </div>

                <div className="hidden lg:block relative">
                  <div className="absolute 2xl:top-[70px] lg:top-20 2xl:right-24">
                    <img src={fleetImg} alt="earn" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="mt-[50px] lg:mt-[100px] lg:px-[100px] 2xl:px-[300px] px-5">
            <FaqsComponent />
          </div> */}

          <div className="mt-[100px]">
            <CustomFooter />
          </div>
        </div>
      </div>
    </>
  );
};

export default FleetOwnerPage;
