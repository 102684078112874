import CustomNavbar from "../../components/layout/custom-navbar";
import TopBanner from "./components/top-banner";
import PageTitileTag from "../../components/general/page-title-tag";
import UsersComponent from "./components/users-component";
import MadeEasyComponent from "./components/made-easy-component";
import WhyComponent from "./components/why-component";
import VideoComponent from "./components/video-component";
import WhyComponent2 from "./components/why-component2";
import AIPoweredComponent from "./components/ai-powered-component";
import DownloadNowComponent from "./components/download-component";
import TestimonyComponent from "./components/testimonyComponent";
import CustomFooter from "../../components/layout/custom-footer";

const HomePage = () => {
  return (
    <>
      <div className="h-screen overflow-hidden">
        <CustomNavbar homeActive="active" />

        <div className="h-full overflow-y-auto">
          <TopBanner />
          {/* about */}
          <div className="mt-[50px] lg:mt-[100px]">
            <div className="flex justify-center">
              <PageTitileTag pageTitle="About" />
            </div>

            <div className="mt-[40px] lg:w-[738px] text-center flex mr-auto ml-auto px-5 lg:px-0">
              <p className="font-[600] text-[18px] lg:text-[26px] leading-[39px] text-yarpGray">
                Yarp Technologies is a leading logistics app company based in
                Accra, Ghana. We specialize in providing innovative solutions to
                the challenges faced by both drivers and users in the logistics
                industry.
              </p>
            </div>
          </div>
          {/* users */}
          <div className="mt-[50px] lg:mt-[100px]">
            <div className="flex justify-center">
              <PageTitileTag pageTitle="Users" />
            </div>
            <p className="text-yarpGray lg:text-[42px] text-[28px] leading-[63px] font-[600] mt-[20px] text-center">
              Who is <span className="text-yarpOrange">Yarp</span> for?
            </p>

            <div className="mt-[82px] px-5 lg:px-0">
              <UsersComponent />
            </div>
          </div>
          {/* move goods */}
          <div className="mt-[50px] lg:mt-[100px]">
            <MadeEasyComponent />
          </div>
          {/* why choose yarp */}
          <div className="mt-[50px] lg:mt-[100px] lg:px-[100px] 2xl:px-[300px] px-5">
            <WhyComponent />
          </div>
          {/* video section */}
          <div className="mt-[50px] lg:mt-[100px] lg:px-[100px] 2xl:px-[300px] px-5">
            <VideoComponent />
          </div>

          {/* why choose yarp2 */}
          <div className="bg-secondaryBG py-[100px] lg:px-[100px] 2xl:px-[300px] px-5">
            <WhyComponent2 />
          </div>

          {/* ai powered section */}
          <div className="lg:pl-[100px] 2xl:pl-[300px] pl-5 mt-[146px] mb-[90px]">
            <AIPoweredComponent />
          </div>

          {/* download now section*/}
          <div className="lg:px-[100px] 2xl:px-[300px] px-5 mt-[146px] mb-[90px]">
            <div className="flex justify-center">
              <div className="w-[628px]">
                <p className="font-[600] text-[42px] leading-[63px] text-yarpGray text-center">
                  Download Now and Get Started Immediately!
                </p>
              </div>
            </div>
            <div className="mt-[32px]">
              <DownloadNowComponent />
            </div>
          </div>

          {/* testimonial */}
          <div className="lg:px-[100px] 2xl:px-[300px] px-5 mt-[146px] mb-[90px] lg:pb-[80px] bg-gradient-to-b from-white to-secondaryBG">
            <div className="flex justify-center">
              <PageTitileTag pageTitle="What They Say" />
            </div>
            <div className="flex justify-center w-full mt-[20px]">
              <p className="text-yarpGray lg:text-[42px] text-[28px] leading-[63px] font-[600] mt-[20px] lg:w-[833px] text-center">
                Users Testimonials
              </p>
            </div>
            <div className="flex justify-center w-full mt-[20px]">
              <p className="text-yarpGray lg:text-[20px] text-[14px] leading-[32px] font-[400] mt-[20px] lg:w-[833px] text-center">
                Discover What Our Customers Have to Say
              </p>
            </div>
            <TestimonyComponent />
          </div>

          {/* faqs */}
          {/* <div className="lg:px-[100px] 2xl:px-[300px] px-5 lg:mt-[146px] mb-[90px] lg:pb-[100px] bg-gradient-to-b from-white to-secondaryBG">
            <FaqsComponent />
          </div> */}

          {/* subscribe */}
          {/* <div className="lg:px-[100px] px-5 lg:mt-[120px] mb-[90px] lg:pb-[55px]">
            <div className="flex justify-center">
              <PageTitileTag pageTitle="Subscribe" />
            </div>
            <div className="flex justify-center w-full mt-[20px]">
              <p className="text-yarpGray lg:text-[42px] text-[28px] lg:leading-[63px] font-[600] mt-[20px] lg:w-[833px] text-center">
                Subscribe to our newsletter
              </p>
            </div>
            <div className="flex justify-center w-full mt-[20px]">
              <p className="text-yarpGray lg:text-[20px] text-[14px] leading-[32px] font-[400] mt-[20px] lg:w-[833px] text-center">
                There are many variations of passages of Lorem Ipsum available
                but the majority have suffered alteration in some form.
              </p>
            </div>
            <div className="flex justify-center">
              <SubscribeComponent />
            </div>
          </div> */}

          {/* footer */}
          <div className="">
            <CustomFooter />
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
