import appleImg from "../../../images/apple-store-large.svg";
import playstoreImg from "../../../images/google-play-large.svg";
import { Link } from "react-router-dom";
import gabbyImg from "../../../images/gabriel.svg";
import { Menu, MenuHandler, MenuList } from "@material-tailwind/react";

const TopBanner = () => {
  return (
    <>
      <div className="pb-4 pl-4 lg:pl-[100px] 2xl:pl-[300px] h-fit bg-secondaryBG">
        <div className="lg:grid lg:grid-cols-2 lg:gap-x-[65px] pt-5 lg:pt-0">
          {/* left */}
          <div className="flex justify-center flex-col h-full lg:py-[40px]">
            <div className="lg:w-[564px]">
              <p className="font-[600] text-[40px] lg:text-[60px] text-yarpGray lg:leading-[74px] leading-[50px]">
                Elevate Your Driving Experience
              </p>

              <div className="lg:mt-[40px] mt-[30px]">
                <p className="lg:text-[20px] text-[18px] font-[400] lg:leading-[32px] leading-[28px] text-yarpGray">
                  The Ultimate Logistics App for Drivers
                </p>
              </div>

              <div className="lg:mt-[70px] mt-[50px] flex gap-[18px] px-5 lg:px-0">
                {/* apple */}
                <Menu placement="bottom-start">
                  <MenuHandler className="outline-none">
                    <button className="h-[60px] w-[200px]">
                      <img src={appleImg} alt="apple" />
                    </button>
                  </MenuHandler>
                  <MenuList className="p-5 flex flex-col gap-y-3">
                    <Link
                      to="https://apps.apple.com/gh/app/yarp-move-goods-everywhere/id6468881078"
                      target="_blank"
                      className="w-full px-5 py-2 rounded-[40px] bg-yarpOrange text-white outline-none"
                    >
                      Download Yarp User
                    </Link>

                    <Link
                      to="https://apps.apple.com/gh/app/yarp-driver-drive-earn-more/id6468880490"
                      target="_blank"
                      className="w-full px-5 py-2 rounded-[40px] bg-yarpBlue text-white outline-none"
                    >
                      Download Yarp Driver
                    </Link>
                  </MenuList>
                </Menu>

                {/* android */}
                <Menu placement="bottom-start">
                  <MenuHandler className="outline-none">
                    <button className="h-[60px] w-[200px]">
                      <img src={playstoreImg} alt="play" />
                    </button>
                  </MenuHandler>
                  <MenuList className="p-5 flex flex-col gap-y-3">
                    <Link
                      to="https://play.google.com/store/apps/details?id=com.yarp.yarp"
                      target="_blank"
                      className="w-full px-5 py-2 rounded-[40px] bg-yarpOrange text-white outline-none"
                    >
                      Download Yarp User
                    </Link>

                    <Link
                      to="https://play.google.com/store/apps/details?id=com.yarp.yarpdriver"
                      target="_blank"
                      className="w-full px-5 py-2 rounded-[40px] bg-yarpBlue text-white outline-none"
                    >
                      Download Yarp Driver
                    </Link>
                  </MenuList>
                </Menu>
              </div>
            </div>
          </div>

          {/* right */}
          <div className="flex items-center justify-end mt-5 lg:mt-0">
            <img src={gabbyImg} alt="truck" className="w-full h-full" />
          </div>
        </div>
      </div>
    </>
  );
};

export default TopBanner;
