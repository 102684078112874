import {
  CurrencyDollarIcon,
  EyeIcon,
  GlobeEuropeAfricaIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";
import phoneImg from "../../../images/yarpPhone1.svg";
import IconsBackground from "../../../components/general/icons-bg";
import PageTitileTag from "../../../components/general/page-title-tag";

const WhyComponent = () => {
  const pageContent = [
    {
      icon: <EyeIcon className="h-8 w-8" />,
      title: "Enhanced Visibility",
      description:
        "Our app provides real-time visibility for drivers, fleet owners and users, eliminating the frustration and delays caused by inaccurate or vague location information.",
    },
    // {
    //   icon: <ChatBubbleLeftRightIcon className="h-8 w-8" />,
    //   title: "Seamless Communication",
    //   description:
    //     "Yarp Technologies facilitates seamless communication between drivers and users, allowing them to exchange information and updates effortlessly.",
    // },
    {
      icon: <GlobeEuropeAfricaIcon className="h-8 w-8" />,
      title: "Efficient Routing",
      description:
        "Our advanced routing algorithms optimize the journey, ensuring the most efficient and time-saving routes for drivers and users.",
    },
    {
      icon: <MapPinIcon className="h-8 w-8" />,
      title: "Reliable Tracking",
      description:
        "Users can track the progress of their deliveries in real-time, offering peace of mind and reducing anxiety.",
    },
    {
      icon: <CurrencyDollarIcon className="h-8 w-8" />,
      title: "Driver Earnings",
      description:
        "Yarp Technologies understands the importance of fair compensation for drivers. Our platform offers competitive earnings, empowering drivers to make decent incomes while providing reliable logistics services.",
    },
  ];

  return (
    <>
      <div className="md:mt-[30px] mt-[400px] lg:mt-[68px] lg:grid lg:grid-cols-2 lg:gap-x-[150px]">
        <div>
          <div className="">
            <PageTitileTag pageTitle="Features" />
          </div>
          <p className="text-yarpGray lg:text-[42px] text-[28px] leading-[63px] font-[600] mt-[20px]">
            Why choose yarp
          </p>
          <div className="mt-[72px]">
            {pageContent.map((item, i) => (
              <div className="flex gap-[24px] mb-[40px]">
                <div>
                  <IconsBackground icon={item?.icon} />
                </div>

                <div>
                  <p className="text-[18px] font-[600] leading-[24px] text-yarpGray">
                    {item?.title}
                  </p>
                  <p className="text-[14px] font-[400] leading-[21px] text-yarpGray mt-[10px]">
                    {item?.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-5 lg:mt-0 flex items-center justify-center">
          <img src={phoneImg} alt="why" />
        </div>
      </div>
    </>
  );
};

export default WhyComponent;
