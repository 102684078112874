interface itemProps {
  pageTitle: string;
}

const PageTitileTag = ({ pageTitle }: itemProps) => {
  return (
    <div className="w-fit py-[4px] border-[1px] border-yarpOrange rounded-[40px] text-yarpOrange px-[14px]">
      {pageTitle}
    </div>
  );
};

export default PageTitileTag;
