import { ChevronLeftIcon, ClockIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import CustomFooter from "../../components/layout/custom-footer";
import { Breadcrumbs } from "@material-tailwind/react";
import SearchComponent from "../../components/general/searchComponent";
import yarpImg from "../../images/yarpLog.svg";
import { useState } from "react";

const UserSupport = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);

  const tabNames = [
    "What is Yarp?",
    "How does Yarp work?",
    "Where is Yarp available?",
    "How can I download the Yarp app?",
    "How can I pay for my rides?",
    "Are Yarp drivers licensed and vetted?",
    "Can I schedule rides in advance with Yarp?",
    "How can I give feedback and rate my experience with the driver through the Yarp APP?",
    "How do I request a ride?",
    "Partnership",
    "Can I take a trip outside of Accra? ",
    "How can I contact the Yarp support Centre? ",
    " What should I do if I forget my package in a Yarp truck?",
    "Can I share my trip details and arrival time with my friends?",
    "Am i safe using Yarp?",
    "Can I add additional ride information? ",
    "NEW FEATURES",
    "ISSUES WITH DRIVER",
    "ISSUE WITH TRUCK",
    "POSITIVE FEEDBACK",
    "Can I pre-book a trip?",
    "Where and how can I raise concerns about Yarp services if any?",
  ];

  const tabComponents = [
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">What is Yarp?</p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          Yarp is a prominent digital logistics platform that enhances the
          process of discovering and reserving trucks by ensuring efficiency and
          transparency for all participants within the logistics ecosystem
        </p>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]"> How does Yarp work?</p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          Yarp operates similarly to other ride services. Users download the
          Yarp app on their smartphones, create an account and provide their
          pickup and drop-off locations. The app then matches them with nearby
          available drivers. Users can track the driver&apos;s arrival, pay for
          their ride through the app, and rate their experience with the driver.
        </p>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">Where is Yarp available?</p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          Yarp is available in Ghana. The availability of Yarp can vary by city,
          so it's best to check the app for specific locations.
        </p>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">How can I download the Yarp app?</p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          The app is available for download on both Android and IOS devices. You
          can find it in the respective app stores for your devices. Search
          “Yarp” and look for the app with the Yarp logo.
        </p>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">How can I pay for my rides?</p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          Yarp offers various payment options including mobile money and cash
          payments. The payment option may vary depending on your location and
          preference
        </p>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">
        Are Yarp drivers licensed and vetted?
      </p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          Yarp requires drivers to meet certain criteria, including possessing a
          valid driver's license, having a registered vehicle in good working
          condition and passing a personal background check.
        </p>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">
        Can I schedule rides in advance with Yarp?{" "}
      </p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          Yes, Yarp allows users to schedule reserved rides. This feature can be
          useful when planning trips or ensuring transport availability during
          busy periods
        </p>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">
        {" "}
        How can I give feedback and rate my experience with the driver through
        the Yarp APP?,
      </p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          At the end of the trip, you are kindly asked to rate your driver and
          experience. One (1) star means you had a bad experience, giving five
          (5) symbolizes a very good experience.
        </p>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">How do I request a ride?</p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        Requesting for a ride with Yarp is very easy. First, you need to
        download the app. Once you are done downloading it, follow these steps:
        <ol className="list-decimal ">
          <li className="font-normal"> Open the app</li>
          <li className="font-normal">Add your destination</li>
          <li className="font-normal">
            Select the type of goods, truck type and the weight you want to
            transport
          </li>
          <li className="font-normal"> Confirm your request.</li>
        </ol>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">Partnership</p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          Are you interested in collaborating with Yarp on a shared project, or
          have ideas to contribute to Yarp's development, kindly reach out to us
          at “Support”. We thoroughly review all incoming ideas and offers.
        </p>
      </div>
    </div>,

    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">
        Can I take a trip outside of Accra?
      </p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        Yes, you can take a trip outside Accra and also to all other parts of
        the nation
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">
        {" "}
        How can I contact the Yarp support Centre?
      </p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          The quickest way to contact the Yarp support team is via your app. All
          you need to do is open your app and choose “Support” from the top left
          menu icon.{" "}
        </p>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">
        What should I do if I forget my package in a Yarp truck?
      </p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          If you leave something behind in a Yarp truck, you will have 24 hours
          to contact the driver via the same app.
        </p>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">
        Can I share my trip details and arrival time with my friends?{" "}
      </p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          Yes, you can share your trip details with trusted family and friends
          during every ride on the App. It makes it easier for your loved ones
          to keep track of your journey and know your arrival time.
        </p>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">Am i safe using Yarp?</p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          User safety is our top priority. We're constantly developing new tools
          to keep you safe and monitoring adherence to our safety standard
        </p>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">
        Can I add additional ride information?
      </p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          Yes, include anything special you need in the Options section, (for
          example, a child safety seat or a truck with air conditioning).
          Certain Options come at an extra charge and the availability may vary
          depending on location. You can also leave a comment for the driver.
          For example, you might say “Wait for me at the gate” to make it easier
          for them to find you{" "}
        </p>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">New Features</p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          We analyze user experience and expand our technological capabilities
          to guarantee steady and sustainable growth. New features, functions
          and tools are added to the app regularly.
        </p>
      </div>
    </div>,

    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">ISSUES WITH DRIVER</p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          If your driver behaved unprofessionally, leave them honest feedback
          after the ride. We check every single rating and all passenger
          feedback. If you felt like you were in danger or something
          unacceptable happened, please contact Support. We'll do everything we
          can to protect you and other users.
        </p>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">ISSUE WITH TRUCK</p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          Let us know if there was anything wrong with the truck. To contact
          Support, use the in-app chat.
        </p>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">POSITIVE FEEDBACK</p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        Good feedback after the ride and a generous tip are the best ways to
        thank your driver. Feel free to also leave a few kind words by
        contacting Support, and we'll make sure the driver gets them.
        <h2 className="font-bold">LOSS OF ITEM </h2>
        <h2 className="mt-4">PHONE</h2>
        If you left behind the phone you requested the ride with, contact us
        from the email linked to your account or via the app after restoring
        your number and logging in and we'll do our best to help you get it
        back. To contact Support, use the in-app chat.
        <h2 className="mt-4">OTHER</h2>
        <h2>It's been less than two days:</h2> Call the driver — they're usually
        happy to return your belongings. The call button in the History section
        is active for 48 hours after the ride.{" "}
        <h2>
          More than two days have passed or no agreement was reached with the
          driver:
        </h2>{" "}
        Describe your lost item and we'll do our best to help you get it back.
        To contact Support, use the in-app chat or email.
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">Can I pre-book a trip?</p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          Yes, you can pre-book up to one week before the requested time and
          date. You also have the option of booking more than one truck
          throughout the week
        </p>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">
        Where and how can I raise concerns about Yarp services if any?
      </p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          You can reach out to our support service team through the numbers
          provided on the APP by call or whatsapp.
        </p>
      </div>
    </div>,
  ];

  return (
    <>
      <div className="h-screen overflow-auto grid grid-rows-5">
        <div className="row-span-2 bg-signupbg relative h-full w-full p-[35px]">
          <div className="absolute h-full w-full bg-black bottom-0 left-0 bg-opacity-[50%]"></div>
          <div className="relative z-10">
            <button className="h-10 w-10" onClick={() => navigate("/support")}>
              <ChevronLeftIcon className="cursor-pointer text-white h-5 w-5" />
            </button>
            <div className="flex items-center h-full text-white flex-col">
              <p className="font-semibold text-2xl">yarp:</p>
              <p className="font-[400] text-[36px] mt-[15px] text-center">
                User Support
              </p>
            </div>
          </div>
        </div>

        <div className="h-full row-span-4 w-full bg-[#fffcf9]">
          <div className="mt-[35px] mb-[200px] 2xl:px-[100px] lg:px-[50px] px-5 w-full">
            <div className="w-full">
              <Breadcrumbs>
                <Link to="/" className="text-[14px] font-400 text-yarpGray">
                  Yarp
                </Link>
                <Link
                  to="/support"
                  className="text-[14px] font-400 text-yarpGray"
                >
                  Yarp Support
                </Link>
                <Link to="#" className="text-[14px] font-400 text-yarpGray">
                  user
                </Link>
              </Breadcrumbs>

              <div className="lg:grid lg:grid-cols-5 w-full 2xl:gap-x-[100px] relative  lg:gap-x-[50px] text-yarpGray mt-[35px] ">
                {/* left */}
                <div className="w-full col-span-1">
                  <p className="text-[14px] font-[600] mb-[16px]">
                    FAQs in this section
                  </p>

                  {tabNames.map((dd, i) => (
                    <div
                      key={i}
                      className="mb-[20px]"
                      onClick={() => setActiveTab(i)}
                    >
                      <Link
                        to="#"
                        className={`${
                          activeTab === i ? "text-yarpOrange" : ""
                        } text-[14px] font-[400]`}
                      >
                        {dd}
                      </Link>
                    </div>
                  ))}

                  {/* <div className="mb-[20px]">
                    <Link
                      to="#"
                      className="text-[14px] font-[600] text-yarpOrange"
                    >
                      Read More
                    </Link>
                  </div> */}
                </div>

                {/* right */}
                <div className="w-full  col-span-2">
                  {tabComponents[activeTab]}
                </div>
              </div>
            </div>
          </div>

          <CustomFooter />
        </div>
      </div>
    </>
  );
};

export default UserSupport;
