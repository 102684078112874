import oneImg from "../../../images/number1.svg";
import twoImg from "../../../images/number2.svg";
import threeImg from "../../../images/number3.svg";
import fourImg from "../../../images/number4.svg";

const HowItWorks = () => {
  return (
    <>
      <div className="lg:mt-[60px] mt-[20px] lg:px-[200px] md:px-[140px] px-[20px]">
        <div className="lg:grid lg:grid-cols-2 gap-x-[50px]">
          <div className="flex gap-[24px] mb-[40px]">
            <img src={oneImg} alt="one" />
            <div>
              <p className="font-[500] lg:text-[25px] text-[14px] leading-[37px] text-yarpGray">
                Download
              </p>
              <p className="font-[400] lg:text-[14px] text-[10px] lg:leading-[21px] text-yarpGray lg:mt-[12px]">
                Download the{" "}
                <span className="text-yarpOrange">Yarp: Driver App</span> from
                the App Store or Google Play
              </p>
            </div>
          </div>

          <div className="flex gap-[24px] mb-[40px]">
            <img src={twoImg} alt="two" />
            <div>
              <p className="font-[500] lg:text-[25px] text-[14px] leading-[37px] text-yarpGray">
                Sign Up
              </p>
              <p className="font-[400] lg:text-[14px] text-[10px] lg:leading-[21px] text-yarpGray lg:mt-[12px]">
                Submit your personal and truck information to proceed to the
                next step
              </p>
            </div>
          </div>

          <div className="flex gap-[24px] mb-[40px]">
            <img src={threeImg} alt="three" />
            <div>
              <p className="font-[500] lg:text-[25px] text-[14px] leading-[37px] text-yarpGray">
                Wait
              </p>
              <p className="font-[400] lg:text-[14px] text-[10px] lg:leading-[21px] text-yarpGray lg:mt-[12px]">
                Wait for the call from our call center after successful
                verification
              </p>
            </div>
          </div>

          <div className="flex gap-[24px] mb-[40px]">
            <img src={fourImg} alt="four" />
            <div>
              <p className="font-[500] lg:text-[25px] text-[14px] leading-[37px] text-yarpGray">
                Drive
              </p>
              <p className="font-[400] lg:text-[14px] text-[10px] lg:leading-[21px] text-yarpGray lg:mt-[12px]">
                After Verification, tap the Lets Drive button and start earning!
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowItWorks;
