import CustomFooter from "../../../components/layout/custom-footer";
import CustomNavbar from "../../../components/layout/custom-navbar";
import DownloadNowComponent from "./download-component";
import FaqsComponent from "./faqs-component";
import TestimonyComponent from "./testimonyComponent";

import gabriel from "../../../images/gabriel.png";
const StreamliningPage = () => {
  return (
    <>
      <div className="h-screen overflow-hidden">
        <CustomNavbar homeActive="active" />

        <div className="h-full overflow-y-auto">
          <div className="relative h-[640px]">
            <div className="absolute inset-0 bg-streamImg bg-cover bg-center">
              <div className="relative flex flex-col items-center justify-center h-full bg-black bg-opacity-50">
                <h1 className="text-white text-center text-[30px] md:text-[74px]">
                  Streamlining Last-Mile Delivery <br /> Our Efficient Solutions
                </h1>
                <p className="md:w-[50%] text-[20px] font-poppins font-[300] text-white text-center mt-10">
                  Yarp Technologies is revolutionizing last-mile delivery with
                  advanced solutions that prioritize efficiency and enhance the
                  customer experience. By optimizing routes, providing real-time
                  tracking, dynamic delivery management, and continuous
                  improvement based on feedback, Yarp Technologies ensures swift
                  and reliable deliveries. With their innovative approach, Yarp
                  Technologies is transforming last-mile delivery, benefiting
                  both customers and e-commerce businesses.
                </p>
              </div>
            </div>
          </div>

          <div className="bg-secondaryBG py-[100px] lg:px-[100px] 2xl:px-[300px] px-5">
            <div className="lg:grid lg:grid-cols-2 lg:gap-x-[50px]">
              <div className="h-full hidden lg:block">
                <img className="" src={gabriel} alt="delivery" />
              </div>

              <div className="flex flex-col justify-center">
                <div className="mt-[20px]">
                  <p className="font-[600] text-[42px] leading-[63px] text-yarpGray">
                    How We do it{" "}
                  </p>

                  <p className="mt-[12px] text-yarpGray font-[400] text-[20px]">
                    Yarp Technologies goes beyond the standard logistics
                    services by offering free insurance coverage for goods in
                    transit. This coverage extends to various types of
                    shipments, including fragile items, high-value products, and
                    perishable goods. By providing comprehensive insurance, Yarp
                    Technologies mitigates the risks associated with
                    transportation, giving businesses and customers confidence
                    in the safety of their goods.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:px-[100px] 2xl:px-[300px] px-5 mt-[146px] mb-[90px] lg:pb-[80px] bg-gradient-to-b from-white to-secondaryBG">
            <div className="w-full flex items-center justify-center">
              <h2 className="w-fit py-[4px] text-center border-[1px] border-[#FF6700] rounded-[40px] text-[#FF6700] px-[14px]">
                What they Say
              </h2>
            </div>
            <div className="flex justify-center w-full mt-[20px]">
              <p className="text-yarpGray lg:text-[42px] text-[28px] leading-[63px] font-[600] mt-[20px] lg:w-[833px] text-center">
                Users Testimonials
              </p>
            </div>
            <div className="flex justify-center w-full mt-[20px]">
              <p className="text-yarpGray lg:text-[20px] text-[14px] leading-[32px] font-[400] mt-[20px] lg:w-[833px] text-center">
                Discover What Our Customers Have to Say
              </p>
            </div>
            <TestimonyComponent />
          </div>

          <div className="lg:px-[100px] 2xl:px-[300px] px-5 mt-[146px] mb-[90px]">
            <div className="flex justify-center">
              <div className="w-[628px]">
                <p className="font-[600] text-[42px] leading-[63px] text-yarpGray text-center">
                  Download Now and Get Started Immediately!
                </p>
              </div>
            </div>
            <div className="mt-[32px]">
              <DownloadNowComponent />
            </div>
          </div>

          <div className="lg:px-[100px] 2xl:px-[300px] px-5 lg:mt-[146px] mb-[90px] lg:pb-[100px] bg-gradient-to-b from-white to-secondaryBG">
            <FaqsComponent />
          </div>

          {/* <div className="lg:px-[100px] px-5 lg:mt-[120px] mb-[90px] lg:pb-[55px]">
            <div className="flex justify-center">
              <PageTitileTag pageTitle="Subscribe" />
            </div>
            <div className="flex justify-center w-full mt-[20px]">
              <p className="text-yarpGray lg:text-[42px] text-[28px] lg:leading-[63px] font-[600] mt-[20px] lg:w-[833px] text-center">
                Subscribe to our newsletter
              </p>
            </div>
            <div className="flex justify-center w-full mt-[20px]">
              <p className="text-yarpGray lg:text-[20px] text-[14px] leading-[32px] font-[400] mt-[20px] lg:w-[833px] text-center">
                There are many variations of passages of Lorem Ipsum available
                but the majority have suffered alteration in some form.
              </p>
            </div>
            <div className="flex justify-center">
              <SubscribeComponent />
            </div>
          </div> */}

          {/* footer */}
          <div className="">
            <CustomFooter />
          </div>
        </div>
      </div>
    </>
  );
};

export default StreamliningPage;
