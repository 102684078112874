import habourImg from "../../../images/haborImg.png";

const StatsComponent = () => {
  //content
  const content = [
    {
      title: (
        <p className="text-[26px] font-[600] leading-[39px] text-yarpGray">
          Ghana's Logistics Sector Ghana's Logistics Sector
        </p>
      ),
      subInfo: (
        <p className="text-[14px] font-[400] leading-[21px] text-[#3E3E59]">
          The logistics industry in Ghana plays a crucial role in supporting the
          country's economy. It contributes significantly to employment and
          revenue generation. In 2020, the logistics sector in Ghana accounted
          for approximately 10% of the country's GDP.
        </p>
      ),
    },
    {
      title: (
        <p className="text-[26px] font-[600] leading-[39px] text-yarpGray">
          African Logistics Market
        </p>
      ),
      subInfo: (
        <p className="text-[14px] font-[400] leading-[21px] text-[#3E3E59]">
          Africa's logistics market is projected to reach a value of $96.38
          billion by 2025, with a compound annual growth rate (CAGR) of 3.9%
          between 2020 and 2025. This growth is driven by factors such as
          expanding e-commerce, urbanization, and increasing foreign
          investments.
        </p>
      ),
    },
    {
      title: (
        <p className="text-[26px] font-[600] leading-[39px] text-yarpGray">
          Driver Empowerment
        </p>
      ),
      subInfo: (
        <p className="text-[14px] font-[400] leading-[21px] text-[#3E3E59]">
          Yarp Technologies aims to address the challenges faced by drivers in
          the logistics industry. According to the World Bank, the
          transportation sector in Africa provides employment opportunities for
          over 10 million people. By offering fair compensation and a reliable
          platform, Yarp Technologies contributes to improving the livelihoods
          of drivers and their families.
        </p>
      ),
    },
  ];

  return (
    <>
      <div className="mt-[30px] lg:mt-[68px] lg:grid lg:grid-cols-2 lg:gap-x-[150px]">
        <div className="mt-5 lg:mt-0 flex items-center justify-center">
          <img src={habourImg} alt="imggg" />
        </div>
        <div>
          {content.map((item, i) => (
            <div key={i} className="mb-[24px]">
              {item?.title}
              <div className="mt-[12px]">{item?.subInfo}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default StatsComponent;
