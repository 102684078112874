import CustomFooter from "../../components/layout/custom-footer";
import TestimonyComponent from "../home-page/components/testimonyComponent";
import DownloadNowComponent from "../home-page/components/download-component";
import CustomNavbar from "../../components/layout/custom-navbar";
import PageTitileTag from "./components/page-title-tag";
import whoImg from "../../images/whoImg.png";
import vissionImg from "../../images/vissionImg.svg";
import vissionIco from "../../images/vissionIco.svg";
import missionImg from "../../images/missionImg.svg";
import missionIco from "../../images/missionIco.svg";
import TopBanner from "./components/top-banner";
import CoreValues from "./components/core-values";
import StatsComponent from "./components/statsComponent";
import OperateComponent from "./components/operateComponent";

const AboutPage = () => {
  return (
    <>
      <div className="h-screen overflow-hidden">
        <CustomNavbar />

        <div className="h-full overflow-y-auto">
          <TopBanner />

          {/* who we are */}
          <div className="lg:px-[100px] 2xl:px-[300px] px-5 2xl:mt-[146px] lg:mt-[146px] mt-[60px] lg:pb-[80px]">
            <div className="mt-[30px] lg:mt-[68px] lg:grid lg:grid-cols-2 lg:gap-x-[150px]">
              <div>
                <div className="">
                  <PageTitileTag pageTitle="Who we are" />
                </div>
                <p className="text-yarpGray lg:text-[42px] text-[28px] leading-[63px] font-[600] mt-[20px]">
                  Welcome to yarp
                </p>
                <div className="mt-[30px]">
                  <p className="text-yarpGray lg:text-[20px] text-[18px] leading-[32px] font-[400]">
                    Yarp Technologies is a leading logistics app company based
                    in Accra, Ghana. We specialize in providing innovative
                    solutions to the challenges faced by both drivers and users
                    in the logistics industry. Our core differentiation lies in
                    our commitment to enhancing visibility and streamlining the
                    process of locating drivers and users, ultimately improving
                    efficiency and customer satisfaction.
                  </p>
                </div>
              </div>
              <div className="mt-5 lg:mt-0 flex items-center justify-center">
                <img src={whoImg} alt="why" />
              </div>
            </div>
          </div>

          {/* vission */}
          <div className="lg:px-[100px] 2xl:px-[300px] px-5 2xl:mt-[146px] lg:mt-[146px] mt-[60px] lg:pb-[80px]">
            <div className="mt-[30px] lg:mt-[68px] lg:grid lg:grid-cols-2 lg:gap-x-[150px]">
              <div>
                <PageTitileTag pageTitle="Enviroment" />
                <div className="mt-[20px] flex items-center gap-[28px]">
                  <img src={vissionIco} alt="visIco" />
                  <p className="text-yarpGray lg:text-[42px] text-[28px] leading-[63px] font-[600]">
                    Our Vision
                  </p>
                </div>
                <div className="mt-[30px]">
                  <p className="text-yarpGray lg:text-[14px] text-[12px] leading-[32px] font-[400]">
                    Our vision is to become the leading logistics app company in
                    Ghana and expand our footprint across Africa. By leveraging
                    technology and prioritizing customer satisfaction, we aim to
                    reshape the logistics landscape, empower drivers, and
                    deliver exceptional value to our users. Join Yarp
                    Technologies today and experience a logistics solution that
                    revolutionizes the way drivers and users connect and
                    operate. Together, let's build a more efficient and thriving
                    logistics ecosystem in Ghana and beyond.
                  </p>
                </div>
              </div>
              <div className="mt-5 lg:mt-0 flex items-center justify-center">
                <img className="w-[300px]" src={vissionImg} alt="vis" />
              </div>
            </div>
          </div>

          {/* mission */}
          <div className="lg:px-[100px] 2xl:px-[300px] px-5 2xl:mt-[76px] lg:mt-[50px] mt-[30px] lg:pb-[80px]">
            <div className="mt-[30px] lg:mt-[68px] lg:grid lg:grid-cols-2 lg:gap-x-[150px]">
              <div className="mt-5 lg:mt-0 flex items-center justify-center">
                <img className="w-[300px]" src={missionImg} alt="vis" />
              </div>
              <div>
                <PageTitileTag pageTitle="Why we are Here" />
                <div className="mt-[20px] flex items-center gap-[28px]">
                  <img src={missionIco} alt="visIco" />
                  <p className="text-yarpGray lg:text-[42px] text-[28px] leading-[63px] font-[600]">
                    Our mission
                  </p>
                </div>
                <div className="mt-[30px]">
                  <p className="text-yarpGray lg:text-[14px] text-[12px] leading-[32px] font-[400]">
                    Our mission at Yarp Technologies is to revolutionize the logistics industry in Ghana and Africa by providing innovative solutions that enhance visibility, streamline operations, and empower drivers. We strive to create an efficient, reliable, and user-friendly platform that brings drivers and users together seamlessly, fostering economic growth and driving positive change in the logistics ecosystem.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* core values */}
          <div className="lg:px-[100px] 2xl:px-[300px] px-5 2xl:mt-[146px] lg:mt-[146px] mt-[60px] mb-[90px] lg:pb-[80px] bg-gradient-to-b from-white to-secondaryBG">
            <div className="flex justify-center">
              <PageTitileTag pageTitle="What  We Cherish" />
            </div>
            <div className="flex justify-center w-full mt-[20px]">
              <p className="text-yarpGray lg:text-[42px] text-[28px] leading-[63px] font-[600] mt-[20px] lg:w-[833px] text-center">
                Our Core Values
              </p>
            </div>
            <div className="flex justify-center w-full mt-[20px]">
              <p className="text-yarpGray lg:text-[20px] text-[14px] leading-[32px] font-[400] mt-[20px] lg:w-[833px] text-center">
                YARP: Your Assurance for Reliable Performance
              </p>
            </div>
            <CoreValues />
          </div>

          {/* statistics */}
          <div className="lg:px-[100px] 2xl:px-[300px] px-5 2xl:mt-[146px] lg:mt-[146px] mt-[60px] mb-[90px] lg:pb-[80px]">
            <div className="flex justify-center">
              <PageTitileTag pageTitle="Statistics" />
            </div>
            <div className="flex justify-center w-full mt-[20px]">
              <p className="text-yarpGray lg:text-[42px] text-[28px] leading-[63px] font-[600] mt-[20px] lg:w-[833px] text-center">
                Ghanaian and African Logistics Industry
              </p>
            </div>
            <div className="flex justify-center w-full mt-[20px]">
              <p className="text-yarpGray lg:text-[20px] text-[14px] leading-[32px] font-[400] mt-[20px] lg:w-[833px] text-center">
                The logistics industry in Ghana and Africa as a whole has been
                experiencing rapid growth and transformation. Here are some key
                statistics:
              </p>
            </div>
            <StatsComponent />
          </div>

          {/* where we operate */}
          <div>
            <OperateComponent />
          </div>

          {/* testimonial */}
          <div className="lg:px-[100px] 2xl:px-[300px] px-5 2xl:mt-[146px] lg:mt-[146px] mt-[60px] mb-[90px] lg:pb-[80px] bg-gradient-to-b from-white to-secondaryBG">
            <div className="flex justify-center">
              <PageTitileTag pageTitle="What They Say" />
            </div>
            <div className="flex justify-center w-full mt-[20px]">
              <p className="text-yarpGray lg:text-[42px] text-[28px] leading-[63px] font-[600] mt-[20px] lg:w-[833px] text-center">
                Users Testimonials
              </p>
            </div>
            <div className="flex justify-center w-full mt-[20px]">
              <p className="text-yarpGray lg:text-[20px] text-[14px] leading-[32px] font-[400] mt-[20px] lg:w-[833px] text-center">
                Discover What Our Customers Have to Say
              </p>
            </div>
            <TestimonyComponent />
          </div>

          {/* download now section*/}
          <div className="lg:px-[100px] 2xl:px-[300px] px-5 2xl:mt-[146px] lg:mt-[146px] mt-[60px] mb-[90px]">
            <div className="flex justify-center">
              <div className="w-[628px]">
                <p className="font-[600] text-[42px] leading-[63px] text-yarpGray text-center">
                  Download Now and Get Started Immediately!
                </p>
              </div>
            </div>
            <div className="mt-[32px]">
              <DownloadNowComponent />
            </div>
          </div>

          {/* footer */}
          <div className="">
            <CustomFooter />
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutPage;
