import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useNavigate } from "react-router-dom";
import PageTitileTag from "../../components/general/page-title-tag";
import CustomFooter from "../../components/layout/custom-footer";

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="h-screen overflow-hidden">
        <div className="grid grid-rows-3 w-full h-full overflow-y-auto">
          <div className="row-span-1 bg-yarpOrange h-full p-[35px]">
            <button className="h-10 w-10" onClick={() => navigate("/")}>
              <ChevronLeftIcon className="cursor-pointer text-white h-5 w-5" />
            </button>
            <div className="flex items-center h-full text-white flex-col">
              <p className="font-semibold text-2xl">yarp:</p>
              <p className="font-semibold lg:text-[42px] text-[30px] mt-[5px]">
                Privacy Policy
              </p>
            </div>
          </div>

          <div className="h-full">
            <div className="2xl:px-[100px] lg:px-[35px] px-5 mb-10">
              <p className="font-semibold lg:text-[42px] text-[30px] text-center mt-[80px]">
                Yarp Technologies - App Privacy Policy
              </p>

              <div className="flex justify-center items-center mt-[30px]">
                <PageTitileTag pageTitle="Last Updated - February 2024" />
              </div>

              <div className="mt-[30px]">
                <p style={{ marginTop: "20px", color: "#656565" }}>
                  Yarp. ("We," “our” or "us") is committed to protecting your
                  privacy. This Privacy Policy explains how your personal data or
                  information is collected, used, disclosed and otherwise
                  processed by Yarp.
                </p>

                <ol style={{ color: "#656565" }}>
                  <li>
                    • This Privacy Policy (“this Policy”) applies to all users of
                    our Website and mobile application named Yarp, and its
                    associated subdomains (collectively, our "Service"). This
                    includes but is not limited to:
                  </li>
                  <li>
                    • Carriers: individuals or entities that offer motor vehicles
                    used to transport goods
                  </li>
                  <li>
                    • Commercial drivers: individuals licensed to operate motor
                    vehicles to transport goods for or on behalf of carriers
                  </li>
                  <li>
                    • Senders/Shippers: any user of the Service that is identified
                    as the shipper, consignor, or consignee, or that owns the
                    goods being transported
                  </li>
                  <li>
                    • Dispatchers: individuals who assign motor vehicles and
                    commercial drivers for conveyance of goods.
                  </li>
                  <li>
                    • By accessing or using our Service, you signify that you have
                    read, understood, and agree to our collection, storage, use,
                    and disclosure of your personal data or information as
                    described in this Privacy Policy and our Terms of Service.
                  </li>
                </ol>

                {/* next */}
                <p
                  style={{
                    fontWeight: "bold",
                    marginBottom: "0px",
                    marginTop: "2rem",
                  }}
                >
                  Definitions and key terms
                </p>
                <i>For this Privacy Policy:</i>

                <p style={{ marginTop: "20px", color: "#656565" }}>
                  • Cookie: small amount of data generated by a website and saved
                  by your web browser. It is used to identify your browser,
                  provide analytics; remember information about you such as your
                  language preference or login information. <br />
                  • Company: when this policy mentions 'Company,' we," "us," or
                  °our," it refers to Yarp Technologies Ltd., a company
                  incorporated under the laws of the Republic of Ghana, with its
                  registered office at No. 26 Kofi Annan Avenue, North Legon –
                  Accra, Ghana that is responsible for your information under this
                  Privacy Policy.
                  <br />
                  • Country: where Yarp or the owners/founders of Yarp are based,
                  that in this case is the Republic of Ghana. • Customer: refers
                  to a company, organization or a natural person that signs up to
                  use the Yarp Service to manage the relationships. <br />
                  • Device: any Internet connected device such as a phone, tablet,
                  computer or any other device that can be used to visit Yarp and
                  use the Service. <br />
                  • IP address: Every device connected to the Internet is assigned
                  a number known as an Internet protocol (IP) address. These
                  numbers are usually assigned in geographic blocks. An IP address
                  can often be used to identify the location from which a device
                  is connecting to the Internet.
                  <br />
                  • Personnel: refers to those individuals who are employed by
                  Yarp or are under contract to perform a service on behalf of one
                  of the parties. <br />
                  • Personal Data: any information that directly, indirectly, or
                  in connection with other information — including a personal
                  identification number — allows for the identification or
                  identification of a natural person. <br />
                  • Service: refers to the service provided by Yarp as described
                  in the related Terms of Service and on this platform. <br />•
                  Third-party service: refers to advertisers, contest sponsors,
                  promotional and marketing partners, and others who provide our
                  content or whose products or services we think may interest you.{" "}
                  <br />• Website: Yarp's site, which can be accessed via this
                  URL:
                  <a href="/">https://www.yarp.co</a> <br />• You: a natural
                  person or entity that is registered with Yarp to use the
                  Service.
                </p>

                {/* next */}
                <p
                  style={{
                    fontWeight: "bold",
                    marginBottom: "0px",
                    marginTop: "2rem",
                  }}
                >
                  What Information Do We Collect?
                </p>
                <p style={{ marginTop: "20px", color: "#656565" }}>
                  1. Data You provide to Yarp
                  <br />
                  <br />
                  We collect data or information directly from you when you visit
                  our Website or use our Service, register as a User by creating
                  an Account, modify or log into your Account, request to use the
                  Service as a Sender/Shipper, request support from or otherwise
                  communicate with us, interact with the Service as a commercial
                  driver or carrier dispatcher, submit information about their
                  vehicle or insurance, or otherwise communicate with us,
                  subscribe to our newsletter; respond to a survey or fill out a
                  form. Without limiting the generality of the foregoing we may
                  collect your <br />
                  • Name / Username <br />
                  • Phone Numbers <br />
                  • Email Addresses <br />
                  • Password <br />
                  • Postal Address <br />• Other information you choose to provide
                  including but not limited to government identification documents
                  including driver’s license numbers and images, dates of birth,
                  signature, vehicle registration and insurance information and
                  payment information.
                  <br />
                  <br />
                  2. Data created during the use of the Service <br />
                  <br />
                  We also collect data or information created during the use of
                  the Service, which includes but is not limited to data collected
                  from mobile devices for a better user experience although these
                  features are completely optional, precise or approximate
                  location data from commercial drivers’ and dispatchers’ mobile
                  devices for which permission has been granted through the
                  permission system used by their mobile device; Transaction
                  information related to the use of the Service, including the
                  type of services requested or provided, date and time the
                  Service was provided, distance traveled, payment amounts, and
                  other related transaction details; app usage and other device
                  data. <br />
                  <br />
                  3. Data from other sources <br />
                  <br />
                  We also collect data from affiliates of Yarp and Third-Party
                  Services that use Yarp’s API
                </p>

                {/* next */}
                <p
                  style={{
                    fontWeight: "bold",
                    marginBottom: "0px",
                    marginTop: "2rem",
                  }}
                >
                  How Do We Use The Information We Collect?
                </p>
                <p style={{ marginTop: "20px", color: "#656565" }}>
                  We collect and use information to arrange for reliable and
                  convenient transportation of shipments, delivery, and other
                  products related to the Service. Any information we collect from
                  you may be used in one of the following ways: <br />
                  <br />
                  • To enhance the safety and security of the Service through the
                  means of device, location, profile, usage, and other data to
                  prevent, detect, and combat fraud or unsafe activities, to
                  authenticate users and to develop safety features. <br />
                  • To personalize your experience (your information helps us to
                  better respond to your individual needs) <br />• To improve our
                  service including to develop new features and to provide or
                  recommend shipments, features, content (we continually strive to
                  improve our service offerings based on the information and
                  feedback we receive from you <br />• To improve customer service
                  including sending communications regarding issues such as the
                  progress of shipments transported through the Service, such as
                  estimated delivery arrival and delivery times. (Your information
                  helps us to more effectively respond to your customer service
                  requests and support needs and to monitor and improve our
                  customer support responses and processes.) <br />• To process
                  transactions from the request of quotations from, and enable the
                  matching of shipments with, carriers and/or commercial drivers
                  to tracking the status and location of shipments transported
                  through the Service, and determine other data relating to use of
                  the Services, such as how long a carrier and/or commercial
                  driver has been at a pickup location to determine detention
                  payments and to process or facilitate payments for the Service.{" "}
                  <br />• To administer a contest, promotion, survey or other site
                  feature <br />• For research and development such as performing
                  internal operations necessary to provide the Service, including
                  troubleshooting software bugs and operational problems; to
                  conduct data analysis, testing, and research; and to monitor and
                  analyze usage and activity trends. This also helps to among
                  others improve and enhance the safety and security of the
                  Service.
                  <br />• In connection with any legal proceedings through
                  investigating or addressing claims or disputes relating to use
                  of the Service, or as otherwise allowed by Applicable Law, or as
                  requested by regulators, government entities, and official
                  inquiries. <br />• To send periodic emails
                </p>

                {/* next */}
                <p
                  style={{
                    fontWeight: "bold",
                    marginBottom: "0px",
                    marginTop: "2rem",
                  }}
                >
                  When do we use customer information from third parties?
                </p>
                <p style={{ marginTop: "20px", color: "#656565" }}>
                  We receive some information from the third parties when you
                  contact us. For example, when you submit your email address to
                  us to show interest in becoming our customer, we receive
                  information from a third party that provides automated fraud
                  detection services to us. We also occasionally collect
                  information that is made publicly available on social media
                  websites. You can control how much of your information social
                  media websites make public by visiting these websites and
                  changing your privacy settings.
                </p>

                {/* next */}
                <p
                  style={{
                    fontWeight: "bold",
                    marginBottom: "0px",
                    marginTop: "2rem",
                  }}
                >
                  Do we share the information we collect with third parties?
                </p>
                <p style={{ marginTop: "20px", color: "#656565" }}>
                  We may share the information that we collect, both personal and
                  non-personal, with third parties such as advertisers, contest
                  sponsors, promotional and marketing partners, and others who
                  provide our content or whose products or services we think may
                  interest you. We may also share it with our current and future
                  affiliated companies and business partners, and if we are
                  involved in a merger, asset sale or other business
                  reorganization, we may also share or transfer your personal and
                  non-personal information to our successors-in-interest. <br />
                  <br />
                  We may engage trusted third party service providers to perform
                  functions and provide services to us, such as hosting and
                  maintaining our servers and our service, database storage and
                  management, e-mail management, storage marketing, payment
                  processing including credit card processing, customer service
                  and fulfilling orders for the Service you may request through
                  our platform._ We will most likely share your personal
                  information, and possibly some non-personal information, with
                  these third parties to enable them to perform these services for
                  us and for you. We may share portions of our log file data,
                  including IP addresses, for analytics purposes with third
                  parties such as web analytics partners, application developers,
                  and ad networks. If your IP address is shared, it may be used to
                  estimate general location and other technographics such as
                  connection speed, whether you have visited the Service in a
                  shared location, and type of device used to visit the Service.
                  They may aggregate information about our advertising and what
                  you see on the Service and then provide auditing, research and
                  reporting for our advertisers and us. <br />
                  <br />
                  We may also disclose personal and non-personal information about
                  you to government or law enforcement officials or private
                  parties in connection with any legal proceedings as we, in our
                  sole discretion, believe necessary or may be appropriate in
                  order to respond to claims, Legal process (including subpoenas),
                  to enforce our Terms of Service, to protect our rights and
                  interests or those of a third party, the safety of the public or
                  any person, to prevent or stop any illegal, unethical, or
                  legally actionable activity, or to otherwise comply with
                  applicable court orders, laws, rules and regulations.
                </p>

                {/* next */}
                <p
                  style={{
                    fontWeight: "bold",
                    marginBottom: "0px",
                    marginTop: "2rem",
                  }}
                >
                  Where and when is information collected from customers and end
                  users?
                </p>
                <p style={{ marginTop: "20px", color: "#656565" }}>
                  We will collect personal information that you directly submit to
                  us. We may also receive personal information about you
                  indirectly from third parties as described above.
                </p>

                {/* next */}

                <p
                  style={{
                    fontWeight: "bold",
                    marginBottom: "0px",
                    marginTop: "2rem",
                  }}
                >
                  How Do We Use Your Email Address?
                </p>
                <p style={{ marginTop: "20px", color: "#656565" }}>
                  By submitting your email address on this app, you agree to
                  receive emails from us_ You can cancel your participation in any
                  of these email lists at any time by clicking on the opt-out link
                  or other unsubscribe option that is included in the respective
                  email. We only send emails to people who have authorized us to
                  contact them, either directly, or through a third party. We do
                  not send unsolicited commercial emails, because we hate spam as
                  much as you do_ By submitting your email address, you also agree
                  to allow us to use your email address for customer audience
                  targeting on sites like Facebook where we display custom
                  advertising to specific people who have opted-in to receive
                  communications from us. Email addresses submitted only through
                  the order-processing page will be used for the sole purpose of
                  sending you information and updates pertaining to your order.
                  If, however, you have provided the same email to us through
                  another method, we may use it for any of the purposes stated in
                  this Policy. Note: If at any time you would like to unsubscribe
                  from receiving future emails, we include detailed unsubscribe
                  instructions at the bottom of each email.
                </p>

                {/* next */}
                <p
                  style={{
                    fontWeight: "bold",
                    marginBottom: "0px",
                    marginTop: "2rem",
                  }}
                >
                  Could my information be transferred to other countries?
                </p>
                <p style={{ marginTop: "20px", color: "#656565" }}>
                  We are incorporated in the Republic of Ghana. Information
                  collected via our website and mobile application, through direct
                  interactions with you, or from use of our help services may be
                  transferred from time to time to our offices or personnel, or to
                  third parties, located throughout the world, and may be viewed
                  and hosted anywhere in the world, including countries that may
                  not have laws of general applicability regulating the use and
                  transfer of such data. To the fullest extent allowed by
                  Applicable Law, by using any of the above, you voluntarily
                  consent to the trans-border transfer and hosting of such
                  information.
                </p>

                {/* next */}
                <p
                  style={{
                    fontWeight: "bold",
                    marginBottom: "0px",
                    marginTop: "2rem",
                  }}
                >
                  Is the information collected through our service secure?
                </p>
                <p style={{ marginTop: "20px", color: "#656565" }}>
                  We take precautions to protect the security of your information.
                  We have physical, electronic, and managerial procedures to help
                  safeguard, prevent unauthorized access, maintain data security,
                  and correctly use your information. However, neither people nor
                  security systems are foolproof, including encryption systems. In
                  addition, people can commit intentional crimes, make mistakes or
                  fail to follow policies. Therefore, while we use reasonable
                  efforts to protect your personal information, we cannot
                  guarantee its absolute security. If Applicable Law imposes any
                  non-disclaimable duty to protect your personal information, you
                  agree that intentional misconduct will be the standards used to
                  measure our compliance with that duty.
                </p>

                {/* next */}
                <p
                  style={{
                    fontWeight: "bold",
                    marginBottom: "0px",
                    marginTop: "2rem",
                  }}
                >
                  Can I update or correct my information?
                </p>
                <p style={{ marginTop: "20px", color: "#656565" }}>
                  The rights you have to request updates or corrections to the
                  information we collect depend on your relationship with us.
                  Personnel may update or correct their information as detailed in
                  our internal Company employment policies. Customers have the
                  right to request the restriction of certain uses and disclosures
                  of personally identifiable information as follows. You can
                  contact us in order to:
                  <br />
                  <br />
                  The rights you have to request updates or corrections to the
                  information we collect depend on your relationship with us.
                  Personnel may update or correct their information as detailed in
                  our internal Company employment policies. Customers have the
                  right to request the restriction of certain uses and disclosures
                  of personally identifiable information as follows. You can
                  contact us in order to:
                  <br />• update or correct your personally identifiable
                  information, <br />• change your preferences with respect to
                  communications and other information you receive from us, or
                  <br />• delete the personally identifiable information
                  maintained about you on our systems (subject to the following
                  paragraph), by cancelling your account. Such updates,
                  corrections, changes and deletions will have no effect on other
                  information that we maintain, or information that we have
                  provided to third parties in accordance with this Privacy Policy
                  prior to such update, correction, change or deletion_ To protect
                  your privacy and security, we may take reasonable steps (such as
                  requesting a unique password) to verify your identity before
                  granting you profile access or making corrections_ You are
                  responsible for maintaining the secrecy of your unique password
                  and account information at all times. You should be aware that
                  it is not technologically possible to remove each and every
                  record of the information you have provided to us from our
                  system. The need to back up our systems to protect information
                  from inadvertent loss means that a copy of your information may
                  exist in a non-erasable form that will be difficult or
                  impossible for us to locate. Promptly after receiving your
                  request, all personal information stored in databases we
                  actively use, and other readily searchable media will be
                  updated, corrected, changed or deleted, as appropriate, as soon
                  as and to the extent reasonably and technically practicable.
                </p>

                {/* next */}
                <p
                  style={{
                    fontWeight: "bold",
                    marginBottom: "0px",
                    marginTop: "2rem",
                  }}
                >
                  Personnel
                </p>
                <p style={{ marginTop: "20px", color: "#656565" }}>
                  If you are one of our workers or applicants, we collect
                  information you voluntarily provide to us. We use the
                  information collected for Human Resources purposes in order to
                  administer benefits to workers and screen applicants. You may
                  contact us in order to <br />
                  <br />• update or correct your information, <br />• change your
                  preferences with respect to communications and other information
                  you receive from us, or <br />• receive a record of the
                  information we have relating to you Such updates, corrections,
                  changes and deletions will have no effect on other information
                  that we maintain, or information that we have provided to third
                  parties in accordance with this Privacy Policy prior to such
                  update, correction, change or deletion.
                </p>

                {/* next */}
                <p
                  style={{
                    fontWeight: "bold",
                    marginBottom: "0px",
                    marginTop: "2rem",
                  }}
                >
                  Sale of Business
                </p>
                <p style={{ marginTop: "20px", color: "#656565" }}>
                  We reserve the right to transfer information to a third party in
                  the event of a sale, merger or other transfer of all or
                  substantially all of our assets or any of its Corporate
                  Affiliates (as defined herein below), or that portion of us or
                  any of its Corporate Affiliates to which the Service relates, or
                  in the event that we discontinue our business or file a petition
                  or have filed against us a petition in bankruptcy,
                  reorganization or similar proceeding, provided that the third
                  party agrees to adhere to the terms of this Privacy Policy.
                </p>

                {/* next */}
                <p
                  style={{
                    fontWeight: "bold",
                    marginBottom: "0px",
                    marginTop: "2rem",
                  }}
                >
                  Affiliates
                </p>
                <p style={{ marginTop: "20px", color: "#656565" }}>
                  We may disclose information (including personal information)
                  about you to our Corporate Affiliates. For purposes of this
                  Privacy Policy, "Corporate Affiliate" means any person or entity
                  which directly or indirectly controls, is controlled by or is
                  under common control with us, whether by ownership or otherwise.
                  Any information relating to you that we provide to our Corporate
                  Affiliates will be treated by those Corporate Affiliates in
                  accordance with the terms of this Privacy Policy.
                </p>

                {/* next */}
                <p
                  style={{
                    fontWeight: "bold",
                    marginBottom: "0px",
                    marginTop: "2rem",
                  }}
                >
                  How Long Do We Keep Your Information?
                </p>
                <p style={{ marginTop: "20px", color: "#656565" }}>
                  We keep your information only so long as we need it to provide
                  the Service to you and fulfill the purposes described in this
                  Policy. This is also the case for anyone that we share your
                  information with and who carries out services on our behalf.
                  When we no longer need to use your information and there is no
                  need for us to keep it to comply with our legal or regulatory
                  obligations, we'll either remove it from our systems or
                  depersonalize it so that we can't identify you. <br />
                  As stated above you may request deletion of your accounts at any
                  time. But we may retain your data after a deletion request due
                  to legal or regulatory requirements for purposes of safety,
                  security, and fraud prevention, or because of an issue relating
                  to your account such as an outstanding credit or an unresolved
                  claim or dispute or for any other reasons stated in this policy.
                </p>

                {/* next */}
                <p
                  style={{
                    fontWeight: "bold",
                    marginBottom: "0px",
                    marginTop: "2rem",
                  }}
                >
                  How Do We Protect Your Information?
                </p>
                <p style={{ marginTop: "20px", color: "#656565" }}>
                  We implement a variety of security measures to maintain the
                  safety of your personal information when you place an order or
                  enter, submit, or access your personal information. We offer the
                  use of a secure server. All supplied sensitive credit
                  information is transmitted via Secure Socket Layer (SSL)
                  technology and then encrypted into our Payment gateway providers
                  database only to be accessible by those authorized with special
                  access rights to such systems and are required to keep the
                  information confidential_ After a transaction, your private
                  information (credit cards, social security numbers, financials,
                  etc) is never kept on file. We cannot, however, ensure or
                  warrant the absolute security of any information you transmit to
                  us or guarantee that your information on the Service may not be
                  accessed, disclosed, altered, or destroyed by a breach of any of
                  our physical, technical, or managerial safeguards.
                </p>

                {/* next */}
                <p
                  style={{
                    fontWeight: "bold",
                    marginBottom: "0px",
                    marginTop: "2rem",
                  }}
                >
                  Governing Law
                </p>
                <p style={{ marginTop: "20px", color: "#656565" }}>
                  The laws of the Republic of Ghana, excluding its conflicts of
                  law rules, shall govern this Agreement and your use of the
                  Service (“Applicable Law”). Your use of the Service may also be
                  subject to other local, national, or international laws.
                </p>

                {/* next */}
                <p
                  style={{
                    fontWeight: "bold",
                    marginBottom: "0px",
                    marginTop: "2rem",
                  }}
                >
                  Your Consent
                </p>
                <p style={{ marginTop: "20px", color: "#656565" }}>
                  By using our service, registering an account, or making a
                  payment on the platform, you consent to this Privacy Policy.
                </p>

                {/* next */}
                <p
                  style={{
                    fontWeight: "bold",
                    marginBottom: "0px",
                    marginTop: "2rem",
                  }}
                >
                  Links to Other Websites
                </p>
                <p style={{ marginTop: "20px", color: "#656565" }}>
                  This Privacy Policy applies only to the Service. The Service may
                  contain links to other websites not operated or controlled by
                  Yarp. We are not responsible for the content, accuracy or
                  opinions expressed in such websites, and such websites are not
                  investigated, monitored or checked for accuracy or completeness
                  by us. Please remember that when you use a link to go from the
                  Service to another website, our Privacy Policy is no longer in
                  effect. Your browsing and interaction on any other website,
                  including those that have a link on our platform, is subject to
                  that website's own rules and policies. Such third parties may
                  use their own cookies or other methods to collect information
                  about you.
                </p>

                {/* next */}
                <p
                  style={{
                    fontWeight: "bold",
                    marginBottom: "0px",
                    marginTop: "2rem",
                  }}
                >
                  Cookies
                </p>
                <p style={{ marginTop: "20px", color: "#656565" }}>
                  A Cookie is a small piece of data or text file stored on your
                  computer or mobile device by your web browser, app or online
                  media. We use "Cookies" to identify the areas of our website,
                  mobile application and other online services (“Online Services”)
                  that you have visited for a number of purposes including
                  authenticating users, remembering user preferences and settings,
                  determining the popularity of content, delivering and measuring
                  the effectiveness of advertising campaigns, analyzing site
                  traffic and trends, and generally understanding the online
                  behaviors and interests of people who interact with our Online
                  Services. We also use Cookies to personalize the Content that
                  you see on our website. Most web browsers can be set to disable
                  the use of Cookies and you have the right to choose whether or
                  not to accept Cookies. However, if you disable Cookies, you may
                  not be able to access functionality on our Online Services
                  correctly or at all. We never place Personally Identifiable
                  Information in Cookies.
                </p>

                {/* next */}
                <p
                  style={{
                    fontWeight: "bold",
                    marginBottom: "0px",
                    marginTop: "2rem",
                  }}
                >
                  Changes To Our Privacy Policy
                </p>
                <p style={{ marginTop: "20px", color: "#656565" }}>
                  If we decide to change our privacy policy, we will post those
                  changes on this page, and update the Privacy Policy modification
                  date below.
                </p>

                {/* next */}
                <p
                  style={{
                    fontWeight: "bold",
                    marginBottom: "0px",
                    marginTop: "2rem",
                  }}
                >
                  Third-Party Services
                </p>
                <p style={{ marginTop: "20px", color: "#656565" }}>
                  We may display, include or make available third-party content
                  (including data, information, applications and other products
                  services) or provide links to third-party websites or services
                  ("Third- Party Services"). You acknowledge and agree that we
                  shall not be responsible for any Third-Party Services, including
                  their accuracy; completeness, timeliness, validity, copyright
                  compliance, legality, decency, quality or any other aspect
                  thereof. We do not assume and shall not have any liability or
                  responsibility to you or any other person or entity for any
                  Third-Party Services. Third Party Services and links thereto are
                  provided solely as a convenience to you and you access and use
                  them entirely at your own risk and subject to such third
                  parties' terms and conditions.
                </p>

                {/* next */}
                <p
                  style={{
                    fontWeight: "bold",
                    marginBottom: "0px",
                    marginTop: "2rem",
                  }}
                >
                  Tracking Technologies
                </p>
                <p style={{ marginTop: "20px", color: "#656565" }}>
                  We use Google Maps APIs to provide map visualization service to
                  users
                  <br />
                  <br />
                  <i>• Google Maps APIs</i>
                  <br />
                  Google Maps API is a robust tool that can be used to create a
                  custom map; a searchable map, check-in functions; display live
                  data synching with location, plan routes, or create a mashup
                  just to name a few. Google Maps API may collect information from
                  You and from Your Device for security purposes. Google Maps API
                  collects information that is held in accordance with its privacy
                  policy. <br />
                  <br />
                  <i>• Local Storage</i> <br />
                  Local Storage sometimes known as DOM storage provides web apps
                  with methods and protocols for storing client-side data. Web
                  storage supports persistent data storage, similar to Cookies but
                  with a greatly enhanced capacity and no information stored in
                  the HTTP request header.
                  <br /> <br />
                </p>
              </div>
            </div>

            <CustomFooter />
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
