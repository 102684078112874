import { ArrowRightIcon } from "@heroicons/react/24/outline";
import getImg from "../../../images/getApp.svg";
import addImg from "../../../images/addImg.svg";
import findImg from "../../../images/findImg.svg";
import moveImg from "../../../images/moveImg.svg";

const MadeEasyComponent = () => {
  return (
    <>
      <div className="bg-secondaryBG lg:h-[354px] h-[440px] py-[65px] px-5 lg:px-0">
        <div className="mb-[80px] flex justify-center items-center">
          <p className="lg:text-[42px] text-[24px] font-[600] text-yarpBlack">
            Moving Goods Made Easy
          </p>
        </div>

        <div className="flex justify-center items-center ">
          <div className="lg:flex lg:gap-[40px] items-center">
            <div className="mb-5 lg:mb-0 flex md:flex-row flex-col  gap-x-[40px] items-center">
              <img src={getImg} alt="get" />
              <p className="text-[14px] font-[400] md:my-0 my-[16px]">
                Get App
              </p>
              <ArrowRightIcon className="h-5 w-5 md:mt-0 mt-2 text-yarpOrange rotate-90 md:rotate-0 " />
            </div>
            <div className="mb-5 lg:mb-0 flex md:flex-row  mt-10 md:mt-0 flex-col gap-x-[40px] items-center">
              <img src={addImg} alt="add" />
              <p className="text-[14px] font-[400] md:my-0 my-[16px]">
                Add Goods Details
              </p>
              <ArrowRightIcon className="h-5 w-5 md:mt-0 mt-2  text-yarpOrange rotate-90 md:rotate-0" />
            </div>
            <div className="mb-5 lg:mb-0 flex md:flex-row flex-col mt-10 md:mt-0 gap-x-[40px] items-center">
              <img src={findImg} alt="find" />
              <p className="text-[14px] font-[400] md:my-0 my-[16px]">
                Find Truck
              </p>
              <ArrowRightIcon className="h-5 w-5 md:mt-0 mt-2 text-yarpOrange rotate-90 md:rotate-0" />
            </div>
            <div className="mb-5 lg:mb-0 flex md:flex-row flex-col mt-10 md:mt-0 gap-x-[40px] items-center">
              <img src={moveImg} alt="move" />
              <p className="text-[14px] font-[400] md:my-0 my-[16px]">
                Move Goods
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MadeEasyComponent;
