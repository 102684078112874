const ContactMap = () => {
  return (
    <>
      <div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3970.3067769767504!2d-0.18722412413942557!3d5.668707232504255!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf9cf51d5cff5b%3A0x14487d242c6853db!2s26%20Kofi%20Annan%20Ave!5e0!3m2!1sen!2sgh!4v1695634805786!5m2!1sen!2sgh"
          width="100%"
          height="550"
          style={{ border: "0" }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </>
  );
};

export default ContactMap;
