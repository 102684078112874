import laptopImg from "../../../images/laptopImg.png";
import PageTitileTag from "../../../components/general/page-title-tag";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const AIPoweredComponent = () => {
  return (
    <>
      <div className="lg:grid lg:grid-cols-2 lg:gap-x-[50px]">
        <div className="lg:w-[550px]  flex flex-col justify-center">
          <PageTitileTag pageTitle="Why Choose Yarp" />

          <div className="mt-[32px]">
            <p className="font-[600] text-[42px] leading-[63px] text-yarpGray">
              AI-Powered Logistics
            </p>

            <p className="mt-[32px] text-yarpGray font-[400] text-[20px]">
              Yarp Technologies harnesses the power of artificial intelligence
              (AI) to revolutionize logistics operations. With our smart
              solutions, Yarp Technologies enhances efficiency, accuracy, and
              customer satisfaction in the logistics industry.
            </p>

            <div className="mt-[52px]">
              <Link
                to="/home/ai_powered_logistics"
                className="flex gap-3 text-yarpOrange items-center"
              >
                <p className="font-[600] text-[18px]">Learn More</p>
                <ArrowRightIcon className="h-5 w-5" />
              </Link>
            </div>
          </div>
        </div>

        <div className="flex items-center mt-10 lg:mt-0">
          <img className="" src={laptopImg} alt="ai" />
        </div>
      </div>
    </>
  );
};

export default AIPoweredComponent;
