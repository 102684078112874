import driverImg1 from "../../../images/driverFeature1.svg";
import driverImg2 from "../../../images/driverFeature2.svg";
import driverImg3 from "../../../images/driverFeature3.svg";
import driverImg4 from "../../../images/driverFeature4.svg";

const FeaturesComponent = () => {
  return (
    <>
      <div className="lg:mt-[127px] mt-[80px] lg:px-[100px] px-[20px]">
        <div className="lg:grid lg:grid-cols-2">
          <div>
            <img src={driverImg1} alt="d1" />
          </div>
          <div className="flex flex-col justify-center">
            <p className="font-[600] lg:text-[24px] text-[20px] text-yarpGray">
              Earn more money on the go
            </p>
            <p className="font-[400] text-[14px] text-yarpGray mt-[20px]">
              Yarp Technologies understands the importance of fair compensation
              for drivers. Our platform offers competitive earnings, empowering
              drivers to make decent incomes while providing reliable logistics
              services.
            </p>
          </div>
        </div>

        <div className="lg:grid lg:grid-cols-2 mt-[70px]">
          <div className="flex flex-col justify-center">
            <p className="font-[600] lg:text-[24px] text-[20px] text-yarpGray">
              Driver safety and well-being
            </p>
            <p className="font-[400] text-[14px] text-yarpGray mt-[20px]">
              Recognizing the importance of holistic driver well-being, Yarp
              offers additional support services to drivers. This include access
              to financial management resources, and mental health support
              programs.
            </p>
          </div>
          <div className="flex justify-center mt-5 lg:mt-0">
            <img src={driverImg2} alt="d1" />
          </div>
        </div>

        <div className="lg:grid lg:grid-cols-2 mt-[70px]">
          <div>
            <img src={driverImg3} alt="d1" />
          </div>
          <div className="flex flex-col justify-center mt-5 lg:mt-0">
            <p className="font-[600] lg:text-[24px] text-[20px] text-yarpGray">
              Claim jobs from the Yarp Market
            </p>
            <p className="font-[400] text-[14px] text-yarpGray mt-[20px]">
              Claim Scheduled Jobs from the market! No Dull moments. With yarp
              you are always on the move
            </p>
          </div>
        </div>

        <div className="lg:grid lg:grid-cols-2 mt-[70px]">
          <div className="flex flex-col justify-center">
            <p className="font-[600] lg:text-[24px] text-[20px] text-yarpGray">
              Free insurance coverage
            </p>
            <p className="font-[400] text-[14px] text-yarpGray mt-[20px]">
              Yarp mitigates the risks associated with transportation, giving
              Drivers confidence in the safety of goods in transit.
            </p>
          </div>
          <div className="flex justify-center">
            <img src={driverImg4} alt="d1" />
          </div>
        </div>
      </div>
    </>
  );
};

export default FeaturesComponent;
