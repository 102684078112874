import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import PageTitileTag from "../../components/general/page-title-tag";
import CustomFooter from "../../components/layout/custom-footer";

const TermsOfService = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="h-screen overflow-auto grid grid-rows-3">
        <div className="row-span-1 bg-yarpOrange h-full p-[35px]">
          <button className="h-10 w-10" onClick={() => navigate("/")}>
            <ChevronLeftIcon className="cursor-pointer text-white h-5 w-5" />
          </button>
          <div className="flex items-center h-full text-white flex-col">
            <p className="font-semibold text-2xl">yarp:</p>
            <p className="font-semibold lg:text-[42px] text-[30px] mt-[35px]">
              Terms Of Service
            </p>
          </div>
        </div>

        <div className="h-full">
          <div className="2xl:px-[100px] lg:px-[35px] px-5 mb-10">
            <p className="font-semibold lg:text-[42px] text-[30px] text-center mt-[80px]">
              Yarp Technologies - Terms Of Service
            </p>

            <div className="flex justify-center items-center mt-[30px]">
              <PageTitileTag pageTitle="Last Updated - February 2024" />
            </div>

            <div className="mt-[30px] text-[#656565]">
              <div className="text-black">
                <p>
                  PLEASE READ THESE WEBSITE TERMS OF SERVICE CAREFULLY BEFORE
                  USING OR REGISTERING ON THIS WEBSITE OR ACCESSING ANY
                  MATERIAL, INFORMATION OR SERVICES THROUGH THIS WEBSITE (THE
                  “WEBSITE”). THE WEBSITE IS AVAILABLE FOR USE ONLY ON THE
                  CONDITION THAT YOU AGREE TO THE TERMS OF USE SET FORTH BELOW.
                  YOUR USE OF THE WEBSITE OR THE SERVICES PROVIDED BY THE
                  WEBSITE SHALL SIGNIFY YOUR ACCEPTANCE OF THE TERMS OF USE AND
                  YOUR AGREEMENT TO BE LEGALLY BOUND BY THE SAME.IF YOU DO NOT
                  AGREE TO ALL OF THE TERMS OF SERVICE, DO NOT ACCESS OR USE THE
                  WEBSITE.
                </p>
                <p className="mt-3">
                  These terms and conditions of use ("Terms of Service") of the
                  Website (as defined below) between YARP TECHNOLOGIES Ltd, YARP
                  (hereinafter referred to as "YARP") and the users/ registrants
                  of the Website ("You" or "Your" or "Yourself" or "User")
                  describe the terms on which YARP offers You access to the
                  Website and the Services (as defined below) through the
                  Website and incorporates by reference other provisions
                  applicable to the use of the Website including but not limited
                  to Supplemental Terms of Service from any changes, amendments
                  or modifications made by YARP.
                </p>
              </div>
              <div>
                <p className="font-semibold text-black mt-10">
                  YOUR USE OF THE WEBSITE IMPLIES THAT YOU AGREE WITH THE TERMS
                  OF USE
                </p>
                <p className="font-semibold text-black mt-5">
                  GENERAL CONTRACTUAL RELATIONSHIP
                </p>

                <p className="mt-5">
                  www.yarp.co (the "Website") is an Internet and Mobile
                  application (“App”) based portal owned and operated by YARP
                  TECHNOLOGIES Ltd, a company incorporated under the laws of the
                  Republic of Ghana, with its registered office at No. 4 Fayza
                  Plaza, Oyarifa - Accra, Ghana. Use of the Website is offered
                  to You conditioned on acceptance of all the terms, conditions
                  and notices contained in these Terms of Service, along with
                  any changes, amendments or modifications made by YARP at its
                  sole discretion from time to time and posted on the Website,
                  including by way of imposing an additional charge for access
                  to or use of a Service.
                </p>
                <p className="mt-5">
                  YARP shall have the right at any time to change or modify the
                  Terms of Service applicable to Your use of the Website, or any
                  part thereof, or to impose new conditions, including, but not
                  limited to, adding fees and charges for use. YARP shall not be
                  required to notify you, whether as a registered user or not,
                  of any changes, modifications or additions made to the Terms
                  of Service. The revised Terms of Service shall be made
                  available on the Website. Your use of the Website and the
                  Services is subject to the most current version of the Terms
                  of Service made available on the Website at the time of such
                  use. You are requested to regularly visit the home page
                  www.yarp.co to view the most current Terms of Service. You can
                  determine when YARP last modified the Terms of Service by
                  referring to the "Last Updated" legend above. It shall be your
                  responsibility to check these Terms of Service periodically
                  for changes modifications or additions. YARP may require you
                  to provide your consent to the updated Terms of Service in a
                  specified manner prior to any further use of the Website and
                  the Services is provided on the Website. If no such separate
                  consent is sought, your continued use of the Website,
                  following changes to the Terms of Service, will constitute
                  your acceptance of those changes.
                  <br />
                  <br />
                  By using the Website or any facility or Service provided by
                  the Website in any way; or merely browsing the Website, You
                  agree that you have read, understood and agreed to be bound by
                  these Terms of Service and the Website's Privacy Policy
                  available here (collectively the Agreement).
                </p>
              </div>
              {/* THE SERVICES */}
              <div className="mt-10">
                <p className="font-semibold text-black">THE SERVICES</p>

                <p className="mt-5">
                  The Services constitute a technology platform that enables You
                  of YARP’s mobile applications or websites provided as part of
                  the Services (each, an “Application”) to arrange and schedule
                  transportation and/or logistics services with independent
                  third party providers of such services, including independent
                  third party transportation providers and independent third
                  party logistics providers under agreement with YARP or certain
                  of YARP’s affiliates (“Third Party Providers”). YOU
                  ACKNOWLEDGE THAT YARP DOES NOT PROVIDE TRANSPORTATION OR
                  LOGISTICS SERVICES OR FUNCTION AS A TRANSPORTATION CARRIER AND
                  THAT ALL SUCH TRANSPORTATION OR LOGISTICS SERVICES ARE
                  PROVIDED BY INDEPENDENT THIRD PARTY CONTRACTORS WHO ARE NOT
                  EMPLOYED BY YARP OR ANY OF ITS AFFILIATES. YARP is only
                  providing the Service to You by facilitating the booking
                  and/or matching of the required vehicles as per Your request
                  as YARP’s customer (the “Customer”) for services (the
                  “Services”).
                </p>
              </div>
              {/* ELIGIBILITY TO USE */}
              <div className="mt-10">
                <p className="font-semibold text-black">ELIGIBILITY TO USE</p>

                <p className="mt-5">
                  The Services are not available to minors under the age of
                  eighteen (18) or to any Users suspended or removed from the
                  YARP system by YARP for any reason whatsoever including but
                  not limited to anything: <br /> 1. That amounts in the
                  violation of law or any the Terms of Service or our policies
                  that apply to your use of the Website;
                  <br /> 2. That is unlawful, misleading or fraudulent; <br />{" "}
                  3. That infringes or breaches any intellectual property
                  rights. <br />
                  <br /> If you do not conform to the above qualification, you
                  shall not be permitted to avail Yourself of the Services or
                  use the Website. You represent that you are of legal age to
                  form a binding contract and are not a person barred from
                  receiving services under the laws as applicable in the
                  Republic of Ghana. YARP reserves the right to refuse access to
                  use the Services offered on the Website to new Users or to
                  terminate access granted to existing Users at any time without
                  according any reasons for doing so. You shall not have more
                  than one active Account on the Website. Additionally, you are
                  prohibited from selling, trading, or otherwise transferring
                  Your Account to another party.
                </p>
              </div>

              {/* USER ACCOUNT, PASSWORD & SECURITY */}
              <div className="mt-10">
                <p className="font-semibold text-black">
                  USER ACCOUNT, PASSWORD & SECURITY
                </p>

                <p className="mt-5">
                  The Website requires you to register as a User by creating an
                  Account in order to avail Yourself of the Services provided by
                  the Website. You will be responsible for maintaining the
                  confidentiality of the Account Information, and are fully
                  responsible for all activities that occur under Your Account.
                  You agree to immediately notify YARP of any unauthorized use
                  of Your Account Information or any other breach of security,
                  and ensure that You exit from Your Account at the end of each
                  session. YARP cannot and will not be liable for any loss or
                  damage arising from your failure to comply with this Section.
                  You shall be held liable for losses incurred by YARP or any
                  other user of or visitor to the Website due to authorized or
                  unauthorized use of Your Account as a result of your failure
                  in keeping Your Account Information secure and confidential.
                  <br />
                  <br />
                  You shall ensure that the Account Information provided by you
                  in the Website's registration form is complete, accurate and
                  up-to-date. Use of another user's Account Information for
                  availing the Services is expressly prohibited.
                  <br />
                  <br />
                  If You provide any information that is untrue, inaccurate, not
                  current or incomplete (or becomes untrue, inaccurate, not
                  current or incomplete), or YARP has grounds to suspect that
                  such information is untrue, inaccurate, not current or
                  incomplete, YARP has the right to suspend or terminate Your
                  Account and refuse any and all current or future use of the
                  Website (or any portion thereof) without incurring any
                  liability to you.
                  <br />
                  <br />
                  You agree that YARP may collect your personal
                  information-including contact information, payment,
                  demographic or behavioral characteristics, technical computer,
                  website usage and social network information or other
                  categories as permitted and described in YARP’s Privacy
                  Policy- for the purposes of the fulfillment of your request
                  for the Services, marketing, communication, account
                  maintenance, or other purposes permitted by our Privacy
                  Policy. Additional privacy notices may apply, including from
                  third party controllers, for the Services or Third Party
                  Services. We will notify you when such notices apply to you.
                </p>
              </div>

              {/* PRICING INFORMATION */}
              <div className="mt-10">
                <p className="font-semibold text-black">PRICING INFORMATION</p>

                <p className="mt-5">
                  YARP strives to provide you with the best prices possible on
                  the Services you buy or avail Yourself of from the Website.
                  <br />
                  <br />
                  You agree to provide correct and accurate credit/ debit card
                  details or any other approved payment method details
                  (including but not limited to mobile money payments) to the
                  approved payment gateway or third party payment platform for
                  availing Yourself of the Services on the Website. You shall
                  not use the credit/ debit card or any other approved payment
                  method details (including but not limited to mobile money
                  payment details), which is not lawfully owned by you in any
                  transaction. You must use your own credit/ debit card or any
                  other approved payment method details (including but not
                  limited to mobile money payment details). The information
                  provided by you may not be utilized or shared with any third
                  party unless required in relation to fraud verifications or by
                  law, regulation or court order. You will be solely responsible
                  for the security and confidentiality of your credit/ debit
                  card details and/or any other approved payment method details
                  (including but not limited to mobile money payment details).
                  YARP expressly disclaims all liabilities that may arise as a
                  consequence of any unauthorized use of your credit/ debit card
                  or any other approved payment method details (including but
                  not limited to mobile money payment details).
                  <br /> <br />
                  <span className="font-semibold">YARP credits:</span> When you
                  deposit money/Make payment into your YARP account, YARP then
                  converts those Cedis to YARP credits. YARP credits can only be
                  used to pay for YARP transactions. YARP does not generally
                  refund any monies deposited to your YARP account to buy YARP
                  credits. If you choose to confirm a deal using YARP credits,
                  the total price will be deducted from your YARP credits.
                </p>
              </div>

              {/* REFUNDS & CANCELLATION POLICY */}
              <div className="mt-10">
                <p className="font-semibold text-black">
                  REFUNDS & CANCELLATION POLICY
                </p>

                <p className="mt-5">
                  YARP will assess refund requests on their merits, considering
                  the digital nature of YARP Subscription and the type of Plan
                  preview that was available before purchase. There is generally
                  no obligation to provide a refund or credit in situations like
                  the following: <br /> <br />
                  • You have changed your mind about an plan;
                  <br /> • You bought a plan by mistake; <br />• You do not have
                  sufficient expertise to use the it; <br />• You ask for
                  goodwill; or <br />
                  • You can no longer access the plan because it has been
                  removed. <br />
                  You may delete your YARP Account at any time but you will not
                  be issued a refund. <br /> <br />
                  Resolving disputes: If Customer and a Vehicle owner cannot
                  come to an agreement about a refund, you can raise a dispute
                  and have YARP investigate the matter. YARP will make a
                  decision based on all available information and you agree that
                  YARP’s decision shall be final and binding on You. Plans with
                  an incorrect price or incorrect information: Despite our
                  reasonable efforts, Plans may occasionally be listed at an
                  incorrect price or with incorrect information. If this
                  happens, YARP may cancel or reverse a transaction, even after
                  it is completed and a payment has been processed. In this
                  event, YARP may promptly arrange for any payment to be
                  credited or refunded and you must not use the Service unless
                  you re-purchase it at the correct price.
                </p>
              </div>

              {/* USAGE CONDUCT */}
              <div className="mt-10">
                <p className="font-semibold text-black">USAGE CONDUCT</p>

                <p className="mt-5">
                  You shall solely be responsible for maintaining the necessary
                  computer equipment and Internet connections that may be
                  required to access use and transact on the Website. You are
                  also under an obligation to use this Website for reasonable
                  and lawful purposes only, and shall not indulge in any
                  activity that is not envisaged by YARP through the Website.{" "}
                  <br />
                  <br />
                  YARP shall be under no liability whatsoever in respect of any
                  loss or damage arising directly or indirectly out of the
                  decline of authorization for any transaction, on account of
                  you having exceeded the preset limit mutually agreed by YARP
                  with our acquiring bank from time to time. You agree that You
                  are solely responsible to YARP and to any third party for any
                  breach of Your obligations under the Terms of Service and for
                  the consequences (including any loss or damage which YARP or
                  its affiliates or its vendors may suffer) for any such breach.{" "}
                  <br />
                  <br />
                  You agree and acknowledge that YARP is not the Driver/Owner of
                  the Vehicles on the YARP Platform. YARP is only providing the
                  Service to You by facilitating the booking and/or matching of
                  the required vehicles as per Your request as YARP’s customer
                  (the “Customer”) for services from a third Party vendor (the
                  “Services”) The Services including any updates, enhancements,
                  new features, and/or the addition of any new Web properties
                  are Subject to the Terms of Service. <br />
                  <br />
                  You agree that YARP may, at any time, modify or discontinue
                  all or part of the Website, charge, modify or waive fees
                  required to use the Website, or offer opportunities to some or
                  all Website Users. You as the Customer is responsible for
                  providing accurate weights, sizes and description of all
                  shipment including the freight class. You agree to provide the
                  means to load and unload all shipment unless the Services have
                  been arranged for as an assessorial service. You as the
                  Customer agree to ensure that your shipment is properly
                  prepared for transport unless the Services have been arranged
                  for as an assessorial service. <br />
                  <br />
                  Further, you undertake not to: <br /> • Upload files that
                  contain software or other material protected by applicable
                  intellectual property laws and treaties unless You own or
                  control the rights thereto or have received all necessary
                  consents. <br />• Upload or distribute files that contain
                  viruses, corrupted files, malicious codes or any other similar
                  software or programs or do anything that could disable,
                  overburden or impair the proper working or appearance of
                  Services or may damage the operation of the Website or
                  another's computer.
                  <br /> • Access or collect data from the Website using
                  automated means (without YARP’s prior permission) or attempt
                  to access data that You do not have permission to access.
                </p>
              </div>

              {/* IP RIGHTS */}
              <div className="mt-10">
                <p className="font-semibold text-black">IP RIGHTS</p>

                <p className="mt-5">
                  The trademarks, logos and service marks displayed on the
                  Website ("Marks") are the property of YARP or its Affiliates,
                  vendors or respective third parties and are protected by and
                  under the copyright, trademark and other intellectual property
                  laws and treaties of the Republic of Ghana. You are not
                  permitted to use the Marks without the prior written consent
                  of YARP, the vendor or the third party that may own the Marks.{" "}
                  <br />
                  <br />
                  YARP makes no proprietary claim to any third party copyrights,
                  names, trademarks or service marks appearing on the Website
                  and unless otherwise indicated or anything contained to the
                  contrary or any proprietary material owned by a third party
                  and so expressly mentioned, YARP owns all intellectual
                  property rights to and into the trademark "YARP", and the
                  Website and its entire contents, including, but not limited
                  to, any and all rights, title and interest in and to
                  copyright, related rights, patents, utility models, designs,
                  know-how, trade secrets and inventions (patent pending),
                  goodwill, source code, meta tags, databases, text, content,
                  graphics, icons, and hyperlinks owned by YARP or licensed to
                  YARP.
                </p>
              </div>

              {/* DISCLAIMER */}
              <div className="mt-10">
                <p className="font-semibold text-black">
                  DISCLAIMER OF WARRANTIES & LIMITATION OF LIABILITY
                </p>

                <p className="mt-5">
                  You expressly understand and agree that, to the maximum extent
                  permitted by applicable law:
                  <br />
                  <br /> 1. The Website, the Services and other materials are
                  provided by YARP on an "as is" basis without warranties of any
                  kind, express, implied, statutory or otherwise, including but
                  limited to the implied warranties of title, non-infringement,
                  merchantability or fitness for a particular purpose. Without
                  limiting the foregoing, YARP makes no warranty that (i) the
                  Website or the Services will meet your requirements or your
                  use of the Website or that the Services will be uninterrupted,
                  timely, secure or error-free; (ii) the results that may be
                  obtained from the use of the Website, the Services or other
                  materials will be effective, accurate or reliable; (iii) the
                  quality of the Website, the Services or other materials will
                  meet your expectations; or that (iv) any errors or defects in
                  the Website, the Services or other materials will be
                  corrected. No advice or information, whether oral or written,
                  obtained by you from YARP or through or from use of the
                  Services shall create any warranty not expressly stated in the
                  Terms of Service.
                  <br />
                  <br /> 2. To the maximum extent permitted by applicable law,
                  YARP will have no liability related to user content arising
                  under intellectual property rights, libel, privacy, publicity,
                  obscenity or other laws. YARP also disclaims all liability
                  with respect to the misuse, loss, modification or
                  unavailability of any user content.
                  <br />
                  <br /> 3. YARP shall not be liable for any loss that you may
                  incur as a consequence of unauthorized use of your account or
                  account information in connection with the Website or any
                  Services or other materials, either with or without your
                  knowledge. YARP has endeavored to ensure that all the
                  information on the Website is correct, but YARP neither
                  warrants nor makes any representations regarding the quality,
                  accuracy or completeness of any data, information, product or
                  service. YARP shall not be responsible for the delay or
                  inability to use the Website or related functionalities, the
                  provision of or failure to provide functionalities, or for any
                  information, software, products, functionalities and related
                  graphics obtained through the Website, or otherwise arising
                  out of the use of the Website, whether based on contract,
                  tort, negligence, strict liability or otherwise. Further, YARP
                  shall not be held responsible for non-availability of the
                  Website during periodic maintenance operations or any
                  unplanned suspension of access to the Website that may occur
                  due to technical reasons or for any reason beyond YARP's
                  control. The user understands and agrees that any material or
                  data downloaded or otherwise obtained through the Website is
                  done entirely at their own discretion and risk and they will
                  be solely responsible for any viruses, spyware and/or damage
                  to their computer systems or loss of data that results from
                  the download of such material or data. YARP accepts no
                  liability for any errors or omissions, with respect to any
                  information provided to you whether on behalf of itself or
                  third parties.
                  <br />
                  <br />
                  4. You as the Customer agree that YARP shall not be liable for
                  any loss, missed delivery or non-delivery caused by the act,
                  default or omission of the Customer or any other party who
                  claims interest in any shipment, or caused by the nature of
                  any shipment or any defect thereof. YARP shall not liable for
                  losses, missed delivery or non-delivery caused by the
                  Customer’s violation(s) of the Terms of Service.
                  <br />
                  <br /> 5. YARP is not liable for losses, missed delivery or
                  non-delivery caused by any events of Force Majeure or the acts
                  of God, perils of the air, public authorities, acts or
                  omissions of Customs or quarantine, wars, riots, strikes,
                  labor disputes, weather conditions or mechanical delay or
                  failure of truck, aircraft or other equipment or any event or
                  condition that: (a)is not reasonably foreseeable and is beyond
                  the reasonable control of YARP; (b) is not an act, event or
                  condition the risks or consequence of which YARP has expressly
                  agreed to assume, (c) YARP could not have been prevented,
                  remedied or cured by it’s reasonable diligence, and (d) makes
                  YARP’s performance of its obligations under the Services
                  impossible or so impractical as to be considered impossible
                  under the circumstances.
                  <br />
                  <br /> 6. Neither YARP nor the actual Carrier of any shipment
                  shall be held liable for delays in delivery caused by; any
                  events of Force Majeure or an Act of God including but not
                  limited to wars or revolutions, terrorism, fires, floods,
                  earthquakes, epidemics, quarantine restrictions, freight
                  embargoes, and strike or lockout accidents, weather conditions
                  or delays due to Regional or Government intervention or other
                  acts of the Government in its sovereign capacity or any other
                  circumstance that are beyond the control of YARP and or the
                  Carrier(s).
                  <br />
                  <br />
                  Notwithstanding anything to contrary, YARP’s entire liability
                  to you under this Terms of Service or otherwise shall be the
                  refund of the money charged from you for any specific service,
                  under which the unlikely liability arises.
                  <br />
                  <br /> In no event shall YARP, its officers, directors,
                  employees, partners or suppliers be liable to You, the vendor
                  or any third party for any special, incidental, indirect,
                  consequential or punitive damages whatsoever, including those
                  resulting from loss of use, data or profits, whether or not
                  foreseeable or whether or not YARP has been advised of the
                  possibility of such damages, or based on any theory of
                  liability, including breach of contract or warranty,
                  negligence or other tortious action, or any other claim
                  arising out of or in connection with Your use of or access to
                  the Website, Services or materials. The limitations and
                  exclusions in this section apply to the maximum extent
                  permitted by applicable law.
                </p>
              </div>

              {/* INDEMNIFICATION */}
              <div className="mt-10">
                <p className="font-semibold text-black">INDEMNIFICATION</p>

                <p className="mt-5">
                  You agree to indemnify, defend and hold harmless YARP
                  including but not limited to its Affiliates, Vendors, agents
                  and employees from and against any and all losses,
                  liabilities, claims, damages, demands, costs and expenses
                  (including legal fees and disbursements in connection
                  therewith and interest chargeable thereon) asserted against or
                  incurred by YARP that arises out of, result from, or may be
                  payable by virtue of, any breach or non-performance of any
                  representation, warranty, covenant or agreement made or
                  obligation to be performed by You pursuant to these Terms of
                  Service. Further, You agree to hold YARP harmless against any
                  claims made by any third party due to, or arising out of, or
                  in connection with, Your use of the Website, any claim that
                  Your material caused damage to a third party, Your violation
                  of the Terms of Service, or Your violation of any rights of
                  another, including any intellectual property rights.
                </p>
              </div>

              {/* INSURANCE OF THE GOODS */}
              <div className="mt-10">
                <p className="font-semibold text-black">
                  INSURANCE OF THE GOODS
                </p>

                <p className="mt-5">
                  YARP has purchased insurance cover for certain risks that may
                  arise during the transportation of your goods (“Goods”). The
                  insurance cover has been purchased by YARP in the form of an
                  insurance policy (the “Policy”) from and underwritten by
                  Hollard Insurance Ghana Limited (“Hollard Insurance”),
                  regulated by the National Insurance Commission. Subject to the
                  Terms and Conditions of the Policy and the extent applicable
                  to which the Policy may cover your Goods, all Goods shall
                  always be shipped at the risk and expense of the
                  Sender/Shipper and/or Consignee and/or the Customer. Goods
                  carried are not insured by YARP, unless the Customer chooses
                  the Insurance Option. YARP does not insure against loss or
                  damage during carriage, storage or transportation of the
                  goods, unless specifically instructed by the Customer by
                  choosing the insurance option. To this end, it is noted that
                  the Customer may take out a separate insurance policy covering
                  the value of the goods transported upon declaration of their
                  value and payment of the corresponding premium by clicking on
                  the insurance button and or accepting the insurance option.
                  When YARP is expressly instructed by the Customer to arrange
                  insurance of goods, YARP shall always contract on behalf of
                  the Customer, acting as agent. The terms and conditions of the
                  insurance shall be those set forth in the insurance policy
                  contracted, which shall be available to the Customer on the
                  Insurance Terms and Conditions page. YARP shall not be liable
                  for any possible disputes or claims that may arise between the
                  Customer and the insurance company contracted, as a
                  consequence of the insurance of the goods. To calculate the
                  value to be insured, the Shipper shall take into account all
                  costs affecting the goods. <span>
                    <Link className="hover:underline text-blue-500 hover:text-blue-600" to="/insurance">Read more on Hollard Insurance Policy</Link>
                  </span>
                </p>
              </div>

              {/* VIOLATION OF THE TERMS OF SERVICE */}
              <div className="mt-10">
                <p className="font-semibold text-black">
                  VIOLATION OF THE TERMS OF SERVICE
                </p>

                <p className="mt-5">
                  You agree that YARP may, in its sole discretion and without
                  prior notice, terminate your access to the Website and block
                  your future access to the Website if YARP determines that you
                  have violated these Terms of Service or Additional Terms. You
                  also agree that any violation by you of these Terms of Service
                  will constitute an unlawful and unfair business practice, and
                  will cause irreparable harm to YARP, for which monetary
                  damages would be inadequate, and you consent to YARP obtaining
                  any injunctive or equitable relief that YARP deems necessary
                  or appropriate in such circumstances. These remedies are in
                  addition to any other remedies YARP may have at law or in
                  equity. <br />
                  <br />
                  You agree that YARP may, in its sole discretion, and without
                  prior notice, terminate Your access to the Website, for cause,
                  which includes (but is not limited to): <br />
                  1. requests by law enforcement or other government agencies;{" "}
                  <br />
                  2. a request by You (self-initiated account deletions);
                  <br />
                  3. discontinuance or material modification of the Website or
                  any <br />
                  service offered on or through the Website; or <br />
                  4. unexpected technical issues or problems. <br />
                  <br />
                  If YARP does take any legal action against you as a result of
                  your violation of these Terms of Use, YARP will be entitled to
                  recover from you, and you agree to pay, all reasonable
                  attorneys' fees and costs of such action, in addition to any
                  other relief granted to YARP.
                </p>
              </div>

              {/* GOVERNING LAW & ARBITRATION */}
              <div className="mt-10">
                <p className="font-semibold text-black">
                  GOVERNING LAW & ARBITRATION
                </p>

                <p className="mt-5">
                  These Terms of Service and all transactions entered into on or
                  through the Website and the relationship between You and YARP
                  shall be governed in accordance with the laws of the Republic
                  of Ghana without reference to conflict of laws principles.{" "}
                  <br />
                  <br />
                  Any dispute, conflict, claim or controversy arising out of or
                  broadly in connection with or relating to the Services or
                  these Terms of Service, including those relating to its
                  validity, its construction or its enforceability or any
                  transactions entered into on or through the Website or the
                  relationship between You and YARP (any “Dispute”) shall be
                  first mandatorily be settled between the Parties using all
                  reasonable endeavours to resolve amicably the Dispute. If such
                  Dispute has not been amicably settled by the Parties within
                  sixty (60) days after receipt by one Party of the other
                  Party’s request for such amicable settlement, such Dispute can
                  be referred to and shall be exclusively and finally resolved
                  by arbitration conducted by a sole arbitrator to be appointed
                  by agreement between the Parties or in default of agreement by
                  the parties, be determined in accordance with the Alternative
                  Dispute Resolution Act, 2010 (Act 798) and the rules of the
                  Ghana Arbitration Centre. The arbitration shall be held in
                  Accra, Ghana and the language of the arbitration shall be
                  English. In accordance with Section 52 of the Alternative
                  Dispute Resolution Act, 2010 (Act 798), the arbitral award
                  shall be final and binding. <br /> <br />
                  No waiver by YARP of any breach or default hereunder shall be
                  deemed to be a waiver of any preceding or subsequent breach or
                  default.
                </p>
              </div>

              {/* HEADINGS */}
              <div className="mt-10">
                <p className="font-semibold text-black">HEADINGS</p>

                <p className="mt-5">
                  The section headings used herein are for convenience only and
                  shall not be given any legal import.
                </p>
              </div>

              {/* ENTIRE AGREEMENT */}
              <div className="mt-10">
                <p className="font-semibold text-black">ENTIRE AGREEMENT</p>

                <p className="mt-5">
                  These Terms of Service and any operating rules for the Website
                  established by YARP constitute the entire agreement with
                  respect to the subject matter hereof, and supersede all
                  previous written or oral agreements between You and YARP with
                  respect to such subject matter.
                </p>
              </div>
            </div>
          </div>

          <CustomFooter />
        </div>
      </div>
    </>
  );
};

export default TermsOfService;
