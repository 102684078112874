import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useNavigate } from "react-router-dom";
import PageTitileTag from "../../components/general/page-title-tag";
import CustomFooter from "../../components/layout/custom-footer";

const InsureancePage = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="h-screen overflow-auto grid grid-rows-3">
        <div className="row-span-1 bg-yarpOrange h-full p-[35px]">
          <button className="h-10 w-10" onClick={() => navigate("/terms-of-service")}>
            <ChevronLeftIcon className="cursor-pointer text-white h-5 w-5" />
          </button>
          <div className="flex items-center h-full text-white flex-col">
            <p className="font-semibold text-2xl">yarp:</p>
            <p className="font-semibold lg:text-[42px] text-[30px] mt-[35px]">
              Hollard Insurance Policy
            </p>
          </div>
        </div>

        <div className="h-full">
          <div className="2xl:px-[100px] lg:px-[35px] px-5 mb-10">
            <p className="font-semibold lg:text-[42px] text-[30px] text-center mt-[80px]">
              Yarp Technologies - Insurance
            </p>

            <div className="flex justify-center items-center mt-[30px]">
              <PageTitileTag pageTitle="Last Updated - February 2024" />
            </div>

            <div className="mt-[30px] text-[#656565]">
              <div>
                <p className="font-semibold text-black mt-10">
                  INSURER: HOLLARD INSURANCE GHANA LIMITED
                </p>
                <p className="font-semibold text-black mt-5">
                  WHAT WE COVER
                </p>

                <p className="mt-5">
                  This insurance shall indemnify the Assured in respect of damage to Goods which they have undertaken to transport and/or handle (including during storage incidental to transit) in their capacity as Freight Forwarders (by Road), Subject to the terms, conditions, warranties, limitations, exclusions and exceptions of the insurance policy.
                </p>

                <div className="mt-5">
                  <p className="font-semibold text-black mt-5">
                    TERRITORIAL LIMITS: GHANA
                  </p>

                  <p className="mt-5">
                    <span className="font-semibold">EXCLUSIONS:</span> In no case shall this insurance indemnify the Assured in respect of liabilities and/or costs and expenses and/or other events defined in the clauses hereunder.
                  </p>

                  <p className="font-semibold text-black mt-5 underline">
                    Excluded Goods Clause:
                  </p>

                  <p className="mt-5">
                    In no case shall this insurance cover any claim loss damage liability or expenses caused by or contributed to, by or arising from (either directly or indirectly) the Assured undertaking Insured Services in respect of any of the following <br />
                    Goods:
                  </p>

                  <p className="mt-5">
                    1.	temperature-controlled Goods except where the Assured transport or store temperature-controlled Goods in the ordinary course of their Insured Services and this is specified under the Insured Services section of this insurance, and/or <br /><br />
                    2.	any time sensitive or critical written material or documents such as (but without prejudice to the generality of this clause) bids and contract proposals, and/or <br /><br />
                    3.	bullion, cash, bank notes, coins of any kind, currency notes, currency or cash equivalents of any kind, stamps and/or vouchers and/or tokens and/or tickets of any kind, credit and/or debit and/or cash dispenser cards of any kind, negotiable securities and any other documents negotiable as or equivalent to cash, and non-negotiables, that is to say crossed cheques and any other documents which are non-negotiable or not equivalent to cash, and/or <br /><br />
                    4.	livestock and bloodstock, and/or <br /><br />
                    5.	one-of-a-kind articles, models or prototypes, and/or <br /><br />
                    6.	any Goods which are contraband or in an illegal trade, and/or <br /><br />
                    7.	any Goods being towed except whilst loaded in or on a trailer, container, or other recognised transport unit, and/or <br /><br />
                    8.	any Goods or Equipment owned by or in the care custody and control of the Assured other than in transit or storage for hire or reward. <br /><br />
                  </p>

                  <p className="font-semibold text-black mt-5 underline">
                    Unexplained Loss or Mysterious Disappearance Exclusion Clause:
                  </p>

                  <p className="mt-5">
                    In no case shall this insurance cover any claim loss damage liability or expenses caused by or contributed to, by or arising from (either directly or indirectly) any unexplained loss or mysterious disappearance of Goods whilst in store including (for example); any unexplained discrepancy between any records made or kept by the Assured and any produced by any customer, any loss subsisting only in a profit and loss comparison or computation, or in the absence of satisfactory physical or recorded evidence of tampering any loss or shortage (1) discovered upon taking inventory or preparing Goods for any movement, and (2) not traceable to an identifiable event. <br />
                    This clause shall not apply where liability for such unexplained loss or mysterious disappearance is incorporated in a Bespoke Customer Contract.  Such claims shall be considered in terms of the Errors & Omissions clause in the Insured Risks section of this insurance.
                  </p>

                  <p className="font-semibold text-black mt-5 underline">
                    Unattended Vehicle Exclusion Clause:
                  </p>

                  <p className="mt-5">
                    In no case shall this insurance cover any claim loss damage liability or expenses caused by or contributed to, by or arising from (either directly or indirectly) theft of or from a vehicle and/or trailer and/or container when left unattended whilst being operated by the Assured unless (1) resulting from the violent and forcible entry into or exit therefrom and, (2) in respect of rest breaks or overnight stops, that such rest breaks or overnight stops are only taken at recognised and designated parking areas for road hauliers. <br />
                    In the event of a claim the onus shall be on the Assured to demonstrate that these procedures were adhered to. <br />
                    Notwithstanding that this clause does not extend to apply to the Assured's sub-contractors, the Assured shall use their best endeavours to ensure that sub-contractors do not leave any vehicle and/or trailer and/or container unattended unless:
                    <br /> <br />
                    a.	all points of entry and/or access to the vehicle and/or cargo space are securely locked and closed and the ignition keys removed from the vehicle whenever left loaded and unoccupied and/or unattended, and <br />
                    b.	that all protective and locking devices, including alarms and immobilisers, are in working order and maintained in accordance with the manufacturer's specifications, and <br />
                    c.	that any rest breaks or overnight stops are only taken at recognised and designated parking areas for road hauliers. <br />
                  </p>

                  <p className="font-semibold text-black mt-5 underline">
                    Customs Exclusion:
                  </p>

                  <p className="mt-5">
                    Other than as may be made directly against the Assured's customer and are specifically insured in terms of the Errors and Omissions Clause in the Insured Risks section of this insurance, in no case shall this insurance cover any claim loss damage liability or expenses as a result of claims made upon the Assured by any customs, tax or other public authority arising out of any Customs Bond Agreement/Guarantee and/or customs processing of whatsoever nature and/or any import/export declaration, clearance, quota, refund and/or tax/excise/duty/suspension procedure whatsoever.
                  </p>

                  <p className="font-semibold text-black mt-5 underline">
                    Defamation, Libel and/or Slander Exclusion Clause:
                  </p>

                  <p className="mt-5">
                    In no case shall this insurance cover any claim loss damage liability or expenses caused by or contributed to, by or arising from (either directly or indirectly) any defamation, libel and/or slander.
                  </p>

                  <p className="font-semibold text-black mt-5 underline">
                    Deliberate and/or Reckless Conduct Exclusion Clause:
                  </p>

                  <p className="mt-5">
                    In no case shall this insurance cover any claim loss damage liability or expenses caused by or contributed to, by or arising from (either directly or indirectly) the deliberate and/or reckless conduct of the directors and officers of the Assured.
                  </p>

                  <p className="font-semibold text-black mt-5 underline">
                    Dishonesty of the Assured Exclusion Clause:
                  </p>

                  <p className="mt-5">
                    In no case shall this insurance cover any claim loss damage liability or expenses caused by or contributed to, by or arising from (either directly or indirectly) any illegal trade, dishonest fraudulent malicious or criminal act or omission of the directors and officers of the Assured.
                  </p>

                  <p className="font-semibold text-black mt-5 underline">
                    Embargo and Sanctions Exclusion Clause:
                  </p>

                  <p className="mt-5">
                    Notwithstanding anything expressed or implied to the contrary in this section or elsewhere in this insurance, in respect of the Assured's activities as both Transport and Warehouse Operator the Insurer is not liable to make any payment for liability under any coverage section of this insurance or make any payment under any Extension for any loss or claim arising in, or where the Assured or any beneficiary under the Policy is a citizen or instrumentality of the government or, any country(ies) against which any laws and/or regulations governing this Policy and/or the Insurer, its parent company or its ultimate controlling entity have established any embargo or other form of economic sanction which have the effect of prohibiting the Insurer to provide insurance coverage, transacting business with or otherwise offering economic benefits to the Assured or any other beneficiary under the Policy. <br />
                    It is further understood and agreed that no benefits or payments will be made to any beneficiary(ies) who is/are declared unable to receive economic benefits under the laws and/or regulations governing this Policy and/or the Insurer, its parent company or its ultimate controlling entity.
                  </p>

                  <p className="font-semibold text-black mt-5 underline">
                    Enumeration of Packages or Units Exclusion Clause:
                  </p>

                  <p className="mt-5">
                    In no case shall this insurance cover any claim for loss damage liability or expenses caused by or contributed to, by or arising from (either directly or indirectly) the Assured as a principal, issuing a bill of lading or other contract of carriage enumerating the packages or other units, and failing to obtain from the carrier or the servants or agents or sub-contractors a bill of lading or other contract of carriage which includes a corresponding enumeration.  This exclusion shall only apply to the extent that the Assured's liability is increased by such occurrence.  In such instances, claims shall only be considered in terms of the Errors and Omissions Clause in the Insured Risks section of this insurance.
                  </p>

                  <p className="font-semibold text-black mt-5 underline">
                    Financial Risks Exclusion Clause:
                  </p>

                  <p className="mt-5">
                    In no case shall this insurance cover any claim loss damage liability or expenses caused by or contributed to, by or arising from (either directly or indirectly) any extension of credit or advance of money by the Assured, or any inability of the Assured to pay or collect accounts, or the insolvency or financial default of the Assured or any person with whom they contracted whether directly or indirectly, or any error or defect in any monetary transaction or in accountancy, including COD and CAD transactions, or the wrongful accounting of customers' funds or funds held in trust on their behalf, or to the extent that any claim loss damage liability or expense is aggravated by the Assured not paying promptly, or at all, any account. <br />
                    This exclusion shall not apply in respect of COD and CAD transactions where the Assured are liable therefore in terms of an International Convention or National Law that applies to the Insured Services declared hereunder.  Such claims shall be considered in terms of the Errors & Omissions clause in the Insured Risks section of this insurance and shall be further sub-limited to an amount not exceeding three times the charges receivable by the Assured for the carriage of the Goods which are the subject of the claim.
                  </p>

                  <p className="font-semibold text-black mt-5 underline">
                    ISM Code Exclusion Clause:
                  </p>

                  <p className="mt-5">
                    In no case shall this insurance cover any claim loss damage liability or expenses caused by or contributed to, by or arising from (either directly or indirectly) Goods being carried by a vessel that is not ISM Code certified or whose owners or operators do not hold an ISM Code Document of Compliance when, at the time of loading of the Goods on board the vessel, the Assured were aware, or in the ordinary course of business should have been aware: <br /> <br />

                    a.	either that such vessel was not certified in accordance in accordance with the ISM Code <br /> <br />
                    OR <br /> <br />
                    b.	that a current Document of Compliance was not held by her owners or operators as required under the SOLAS Convention 1974 as amended. <br /> <br />
                    The ISM Code means the International Safety Management Code incorporated into the International Convention for the Safety of Life at Sea 1974 (SOLAS).
                  </p>

                  <p className="font-semibold text-black mt-5 underline">
                    ISPS Exclusion Clause:
                  </p>

                  <p className="mt-5">
                    In no case shall this insurance cover any claim loss damage liability or expense caused by or contributed to, by or arising from (either directly or indirectly) Goods being carried by a vessel that does not hold a valid International Ship Security Certificate as required under the International Ship and Port Facility Security (ISPS) Code when, at the time of loading of the Goods on board the vessel, the Assured were aware, or in the ordinary course of business should have been aware that such vessel was not certified in accordance with the ISPS Code as required under the SOLAS Convention 1974 as amended.
                  </p>

                  <p className="font-semibold text-black mt-5 underline">
                    Institute Radioactive Contamination, Chemical, Biological, Bio-Chemical and Electromagnetic Weapons Exclusion Clause (CL. 370 dated 10/11/2003):
                  </p>

                  <p className="mt-5">
                    As attaching to and forming part of this insurance.
                  </p>

                  <p className="font-semibold text-black mt-5 underline">
                    Institute Cyber Attack Exclusion Clause (CL. 380 dated 10/11/03):
                  </p>

                  <p className="mt-5">
                    As attaching to and forming part of this insurance.
                  </p>

                  <p className="font-semibold text-black mt-5 underline">
                    Loss of Life or Personal Injury Exclusion Clause:
                  </p>

                  <p className="mt-5">
                    In no case shall this insurance cover any claim loss damage liability or expenses caused by or contributed to, by or arising from (either directly or indirectly) death of or bodily injury to or illness or trauma of or any syndrome suffered by a person or living creature.
                  </p>

                  <p className="font-semibold text-black mt-5 underline">
                    Performance Guarantee Exclusion Clause:
                  </p>

                  <p className="mt-5">
                    In no case shall this insurance cover any claim loss damage liability or expenses caused by or contributed to, by or arising from (either directly or indirectly) any contract guarantee, performance bond or penalty clause, or any deadline whatsoever howsoever incorporated into any agreement or contract into which the Assured entered in respect of or in connection with any Goods and/or any service provided or procured by the Assured.
                  </p>

                  <p className="font-semibold text-black mt-5 underline">
                    Process Exclusion Clause:
                  </p>

                  <p className="mt-5">
                    In no case shall this insurance cover any claim loss damage liability or expenses caused by or contributed to, by or arising from (either directly or indirectly) any process of use, testing, repair, packing, assembly, or any other work upon or treatment of any Goods or resulting directly or indirectly from any such process.ed.
                  </p>

                  <p className="font-semibold text-black mt-5 underline">
                    Pollution and Contamination Exclusion Clause:
                  </p>

                  <p className="mt-5">
                    In no case shall this insurance cover any claim loss damage liability or expenses caused by or contributed to, by or arising from (either directly or indirectly) any pollution and/or contamination and/or damage to the environment, third party, public or private property whatsoever howsoever arising and/or any threat thereof whatsoever howsoever arising.
                  </p>

                  <p className="font-semibold text-black mt-5 underline">
                    Public Policy Exclusion Clause:
                  </p>

                  <p className="mt-5">
                    In no case shall this insurance cover any claim loss damage liability or expenses caused by or contributed to, by or arising from (either directly or indirectly) any event whatsoever in respect of which indemnity from Insurer is contrary to public policy.
                  </p>

                  <p className="font-semibold text-black mt-5 underline">
                    Punitive or Exemplary Damages Exclusion Clause:
                  </p>

                  <p className="mt-5">
                    In no case shall this insurance cover any claim loss damage liability or expenses caused by or contributed to, by or arising from (either directly or indirectly) any punitive, exemplary, penalty or multiple damages awarded against the Assured or any person for whom the Assured may be held liable.
                  </p>

                  <p className="font-semibold text-black mt-5 underline">
                    Temperature Controlled Goods Exclusion Clause:
                  </p>

                  <p className="mt-5">
                    In no case shall this insurance cover any claim loss damage liability or expenses caused by or contributed to, by or arising from (either directly or indirectly) any failure malfunction derangement or breakdown of any refrigeration or chilling or cooling plant or machinery or equipment or any seepage or leakage of refrigerant or any unsuitability or insufficiency of insulation or any failure to set and/or check and/or regulate properly or at all any temperature control and/or observe any temperature indicator and/or take all reasonable steps to maintain any stipulated temperature or keep within any stipulated range of temperature. <br />
                    This Temperature Controlled Goods Exclusion clause shall not apply provided always that the Assured complies with all the following conditions precedent: <br /><br />
                    1.	the procedures prepared by the Assured for such Goods are fully operative and have been fully set down and fully disclosed to and accepted by the Insurer, and <br />
                    2.	the Assured ensures that the refrigeration equipment is maintained and serviced in strict accordance with manufacturer's instructions, and <br />
                    3.	the Assured shall produce on request by the Insurer maintenance and service records of the refrigeration equipment, and <br />
                    4.	the Assured shall ensure that all refrigeration equipment used can maintain the temperature at which the Assured are instructed to carry and/or store the Goods for the duration of the transit or storage. <br />
                  </p>

                  <p className="font-semibold text-black mt-5 underline">
                    War, Strikes and Terrorism Exclusion Clause:
                  </p>

                  <p className="mt-5">
                    In no case shall this insurance cover any claim loss damage liability or expenses caused by or contributed to, by or arising from (either directly or indirectly) war, civil war, revolution, rebellion, insurrection or civil strife arising therefrom, or any hostile act by or against a belligerent power; confiscation or expropriation; capture, seizure, arrest, restraint or detainment and the consequences thereof or any attempt thereat; any terrorist act or any person(s) acting maliciously or from a political or ideological motive; strikers, locked-out workmen, or persons taking part in labour disturbance, riots or civil commotions; derelict mines, torpedoes, bombs or other derelict weapons of war.
                  </p>

                  <p className="font-semibold text-black mt-5 underline">
                    Wrongful Release of Cargo Exclusion Clause:
                  </p>

                  <p className="mt-5">
                    In no case shall this insurance cover any claim loss damage liability or expenses caused by or contributed to, by or arising from (either directly or indirectly) the release of Goods unless: <br /><br />
                    a.	in respect of negotiable bills of lading or other documents of title, against the surrender of the original bill of lading or document of title <br /> <br />
                    and <br /> <br />
                    b.	in respect of air waybills, straight bills of lading or other non-negotiable document of carriage, upon presentation of the original (and not a photocopy, facsimile copy, or electronic copy) document of carriage or where applicable the bank release letter from the bank named on the air waybill or other similar document of carriage. <br /> <br />
                    This exclusion shall not apply where an employee of the Assured unwittingly releases Goods contrary to the manner described above.  In such instances, claims shall be considered in terms of the Errors & Omissions clause in the Insured Risks section of this insurance.
                  </p> <br /><br />

                </div>
              </div>
            </div>
          </div>

          <CustomFooter />
        </div>
      </div>
    </>
  );
};

export default InsureancePage;
