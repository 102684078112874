import { ChevronLeftIcon, ClockIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import CustomFooter from "../../components/layout/custom-footer";
import { Breadcrumbs } from "@material-tailwind/react";
import SearchComponent from "../../components/general/searchComponent";
import yarpImg from "../../images/yarpLog.svg";
import { useState } from "react";

const DriverSupport = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);

  const tabNames = [
    "What is Yarp?",
    "Where is Yarp available?",
    "How can I download the Yarp app?",
    "How can I pay for my rides?",
    "Are Yarp drivers licensed and vetted?",
    "How can I contact the Yarp support Centre?",
    "Am I safe using Yarp?",
    "Partnership",
    "New Features",
    "What are the rules at the Pick Up point?",
    "How do I turn auto-navigation on?",
    "How can I control what types of trip requests I receive?",
    "What is the pricing for a trip?",
    "Where and how can I raise concerns about Yarp services if any?",
    "Where and how can I raise concerns about Yarp services if any?",
    "Is there a way to earn bonuses with Yarp?",
    "What happens if I get involved in an accident while delivering goods in the name of Yarp?",
    "How are driver ratings calculated?",
    "What vehicle requirement does Yarp take into consideration while recruiting trucks?",
    "What safety and security features are included in the Yarp driver app?",
    "What device do I need to work with Yarp?",
    "How do I get paid for completed trips?",
    "What perfect age does Yarp take into consideration?",
  ];

  const tabComponents = [
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">What is Yarp?</p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          Yarp is a prominent digital logistics platform that enhances the
          process of discovering and reserving trucks by ensuring efficiency and
          transparency for all participants within the logistics ecosystem
        </p>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">Where is Yarp available?</p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          Yarp is available in Ghana. The availability of Yarp can vary by city,
          so it's best to check the app for specific locations.
        </p>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">How can I download the Yarp app?</p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          The app is available for download on both Android and IOS devices. You
          can find it in the respective app stores for your devices. Search
          “Yarp” and look for the app with the Yarp logo.
        </p>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">How can I pay for my rides?</p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          Yarp offers various payment options including mobile money and cash
          payments. The payment option may vary depending on your location and
          preference
        </p>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">
        Are Yarp drivers licensed and vetted?
      </p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          Yarp requires drivers to meet certain criteria, including possessing a
          valid driver's license, having a registered vehicle in good working
          condition and passing a personal background check.
        </p>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">
        How can I contact the Yarp support Centre?
      </p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          The quickest way to contact the Yarp support team is via your app. All
          you need to do is open your app and choose “Support” from the top left
          menu icon.
        </p>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">Am I safe using Yarp?</p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          User safety is our top priority. We're constantly developing new tools
          to keep you safe and monitoring adherence to our safety standard
        </p>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">Partnership</p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          Are you interested in collaborating with Yarp on a shared project, or
          have ideas to contribute to Yarp's development, kindly reach out to us
          at “Support”. We thoroughly review all incoming ideas and offers.
        </p>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">New Features</p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          We analyze user experience and expand our technological capabilities
          to guarantee steady and sustainable growth. New features, functions
          and tools are added to the app regularly.
        </p>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">
        What are the rules at the Pick Up point?
      </p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          <ul>
            <li>1. No speeding</li> <br />
            <li>2. No smoking</li> <br />
            <li>3. No touting and conducting offline trips</li> <br />
            <li>4. No blocking driveways</li> <br />
            <li>
              5. Follow all guidelines communicated by Yarp within the process
            </li>{" "}
            <br />
          </ul>
        </p>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">
        How do I turn auto-navigation on?
      </p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          <ul>
            <li>1. Tap your profile photo (top right corner).</li> <br />
            <li>2. Tap 'Account', then tap 'App Settings'.</li> <br />
            <li>3. Tap 'Navigation' and enable auto-navigation. </li> <br />
          </ul>
        </p>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">What is the pricing for a trip?</p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          The pricing is based on trip distance. Tariffs differ by city.
        </p>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">
        How can I control what types of trip requests I receive?
      </p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          From 'Trip planner' tap the toggle icon in the lower-right corner.
          This should take you to your 'Driving Preferences' screen. <br />{" "}
          <br />
          From there, you can choose to turn on or off the different types of
          trip requests.
        </p>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">
        Where and how can I raise concerns about Yarp services if any?
      </p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          You can reach out to our support service team through the numbers
          provided on the APP by call or whatsapp.
        </p>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">
        Where and how can I raise concerns about Yarp services if any?
      </p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          You can reach out to our support service team through the numbers
          provided on the APP by call or whatsapp.
        </p>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">
        Is there a way to earn bonuses with Yarp?
      </p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          Yes, but depends on the total number of trips and performance.
        </p>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">
        What happens if I get involved in an accident while delivering goods in
        the name of Yarp?
      </p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          Yarp has an insurance cover policy for all our registered and approved
          drivers.
        </p>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">
        How are driver ratings calculated?
      </p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          Driver ratings are calculated based on severity and duration of
          driving events including harsh acceleration, hard braking, speeding
          over the limit etc.
        </p>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">
        What vehicle requirement does Yarp take into consideration while
        recruiting trucks?
      </p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          Active INSURANCE, ROADWORTHY certificates and a valid National ID.
        </p>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">
        What safety and security features are included in the Yarp driver app?
      </p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          Real-Time GPS Tracking for ongoing trips, Secure Payment Options, User
          Verification and Rating System.
        </p>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">
        What device do I need to work with Yarp?
      </p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          Smartphone, Driving license, a roadworthy truck and a valid national
          ID.
        </p>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">
        How do I get paid for completed trips?
      </p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          You get paid by the user after delivering goods in good condition
          through the APP or cash payments.
        </p>
      </div>
    </div>,
    <div className="sticky z-[999999] top-0">
      <p className="font-[600] text-[26px]">
        What perfect age does Yarp take into consideration?
      </p>
      <div className="mt-[20px] flex gap-[20px] items-center">
        <img src={yarpImg} alt="yarp" />
        <p className="text-[14px] font-[400]">Yarp Ghana</p>
      </div>

      <div className="mt-[20px] bg-white shadow-lg p-[30px] rounded-[20px] w-full">
        <p className="text-[14px] font-[400px] mb-[20px]">
          Twenty (20) years and above.
        </p>
      </div>
    </div>,
  ];

  return (
    <>
      <div className="h-screen overflow-auto grid grid-rows-5">
        <div className="row-span-2 bg-signupbg relative h-full w-full p-[35px]">
          <div className="absolute h-full w-full bg-black bottom-0 left-0 bg-opacity-[50%]"></div>
          <div className="relative z-10">
            <button className="h-10 w-10" onClick={() => navigate("/support")}>
              <ChevronLeftIcon className="cursor-pointer text-white h-5 w-5" />
            </button>
            <div className="flex items-center h-full text-white flex-col">
              <p className="font-semibold text-2xl">yarp:</p>
              <p className="font-[400] text-[36px] mt-[15px] text-center">
                Driver Support
              </p>
            </div>
          </div>
        </div>

        <div className="h-full row-span-4 w-full bg-[#fffcf9]">
          <div className="mt-[35px] mb-[200px] 2xl:px-[100px] lg:px-[50px] px-5 w-full">
            <div className="w-full">
              <Breadcrumbs>
                <Link to="/" className="text-[14px] font-400 text-yarpGray">
                  Yarp
                </Link>
                <Link
                  to="/support"
                  className="text-[14px] font-400 text-yarpGray"
                >
                  Yarp Support
                </Link>
                <Link to="#" className="text-[14px] font-400 text-yarpGray">
                  Driver
                </Link>
              </Breadcrumbs>

              <div className="lg:grid lg:grid-cols-5 w-full 2xl:gap-x-[100px] lg:gap-x-[50px] text-yarpGray mt-[35px] ">
                {/* left */}
                <div className="w-full col-span-1">
                  <p className="text-[14px] font-[600] mb-[16px]">
                    FAQs in this section
                  </p>

                  {tabNames.map((dd, i) => (
                    <div
                      key={i}
                      className="mb-[20px]"
                      onClick={() => setActiveTab(i)}
                    >
                      <Link
                        to="#"
                        className={`${
                          activeTab === i ? "text-yarpOrange" : ""
                        } text-[14px] font-[400]`}
                      >
                        {dd}
                      </Link>
                    </div>
                  ))}

                  {/* <div className="mb-[20px]">
                    <Link
                      to="#"
                      className="text-[14px] font-[600] text-yarpOrange"
                    >
                      Read More
                    </Link>
                  </div> */}
                </div>

                {/* right */}
                <div className="w-full col-span-2">
                  {tabComponents[activeTab]}
                </div>
              </div>
            </div>
          </div>

          <CustomFooter />
        </div>
      </div>
    </>
  );
};

export default DriverSupport;
