import React from "react";
import aboutBg from "../../../images/about_bg_yarp.jpg"

const TopBanner = () => {
  return (
    <>
      <div className="relative">
        <img
          className="w-full h-full object-cover"
          src={aboutBg}
          alt="gb"
        />
        <div className="absolute bg-black h-full w-full bottom-0 bg-opacity-[60%]">
          <div className="flex justify-center items-center text-white h-full">
            <p className="font-[600] 2xl:text-[42px] lg:text-[32px] text-[24px] 2xl:leading-[63px]">
              About Us
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopBanner;
