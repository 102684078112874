import CustomNavbar from "../../components/layout/custom-navbar";
import TopBanner from "./components/top-banner";
import PageTitileTag from "../../components/general/page-title-tag";
import FeaturesComponent from "./components/features-component";
import HowItWorks from "./components/how-it-works";
import DownloadNowComponent from "./components/download-component";
import CustomFooter from "../../components/layout/custom-footer";

const DriverPage = () => {
  return (
    <>
      <div className="h-screen overflow-hidden">
        <CustomNavbar driverActive="active" />

        <div className="h-full overflow-y-auto">
          <TopBanner />

          {/* features */}
          <div className="mt-[50px] lg:mt-[100px] lg:px-[118px] 2xl:px-[300px] px-5">
            <div className="flex justify-center">
              <PageTitileTag pageTitle="Features" />
            </div>
            <p className="text-yarpGray lg:text-[32px] text-[28px] leading-[63px] font-[600] mt-[20px] text-center">
              Get the Best for being a yarp driver
            </p>
            <p className="text-yarpGray lg:text-[20px] text-[16px] leading-[32px] font-[400] mt-[20px] text-center">
              Completely synergize resource taxing relationships via premier
              niche markets. Professionally cultivate customer service.
            </p>

            <FeaturesComponent />
          </div>

          {/* join us */}
          <div className="mt-[50px] lg:mt-[100px] lg:px-[100px] 2xl:px-[300px] px-5">
            <div className="flex justify-center">
              <PageTitileTag pageTitle="Join Us" />
            </div>
            <p className="text-yarpGray lg:text-[32px] text-[28px] leading-[63px] font-[600] mt-[20px] text-center">
              How It works
            </p>

            <HowItWorks />
          </div>

          {/* download */}
          <div className="mt-[50px] lg:mt-[100px] lg:px-[100px] 2xl:px-[300px] px-5">
            <DownloadNowComponent />
          </div>

          {/* <div className="mt-[50px] lg:mt-[100px] lg:px-[100px] 2xl:px-[300px] px-5">
            <FaqsComponent />
          </div> */}

          <div className="mt-[100px]">
            <CustomFooter />
          </div>
        </div>
      </div>
    </>
  );
};

export default DriverPage;
