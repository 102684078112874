import {
  ArchiveBoxIcon,
  CubeTransparentIcon,
  TruckIcon,
} from "@heroicons/react/24/outline";
import IconsBackground from "../../../components/general/icons-bg";

const UsersComponent = () => {
  const contents = [
    {
      icon: <ArchiveBoxIcon className="h-8 w-8" />,
      title: "Goods Owners",
      description:
        "Yarp gives you the ability to efficiently monitor and manage movement of goods from start to finish, catering to manufacturers, distributors and retailers.",
    },
    {
      icon: <CubeTransparentIcon className="h-8 w-8" />,
      title: "Drivers",
      description:
        "Yarp is the ultimate tool for drivers, providing navigation, load-info, realtime updates, and digital proof of delivery to ensure safe and efficient journeys.",
    },
    {
      icon: <TruckIcon className="h-8 w-8" />,
      title: "Fleet Owners",
      description:
        "Maximize your fleet's potential with Yarp: real-time tracking, cost reduction, and growth prospects. Elevate your fleet management now.",
    },
  ];
  return (
    <>
      <div className="px-5 lg:px-[100px] 2xl:px-[300px]">
        <div className="lg:grid lg:grid-cols-3 gap-x-[57px]">
          {contents.map((item, i) => (
            <div
              className="p-[40px] rounded-[20px] shadow-md mb-5 lg:mb-0"
              key={i}
            >
              <div>
                <IconsBackground icon={item?.icon} />
                <p className="mt-[20px] font-[600] text-[24px] text-yarpGray">
                  {item?.title}
                </p>
                <p className="mt-[20px] font-[400] text-[14px] text-yarpGray">
                  {item?.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default UsersComponent;
