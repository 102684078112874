import React, { useEffect } from "react";
import {
  Navbar,
  MobileNav,
  Typography,
  IconButton,
} from "@material-tailwind/react";
import logo from "../../images/yarp-nav-logo.svg";
import { Link } from "react-router-dom";

interface navProps {
  homeActive?: string;
  driverActive?: string;
  fleetActive?: string;
  contactActive?: string;
}

const CustomNavbar = ({
  homeActive,
  driverActive,
  fleetActive,
  contactActive,
}: navProps) => {
  const [openNav, setOpenNav] = React.useState(false);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  //nav lists
  const navList = (
    <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:gap-[40px] lg:items-center">
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 text-[14px] font-[400] hover:text-yarpOrange"
      >
        <Link to="/" className={homeActive}>
          Home
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 text-[14px] font-[400] hover:text-yarpOrange"
      >
        <Link to="/driver" className={driverActive}>
          Driver
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 text-[14px] font-[400] hover:text-yarpOrange"
      >
        <Link to="/fleet-owner" className={fleetActive}>
          Fleet Owner
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 text-[14px] font-[400] hover:text-yarpOrange"
      >
        <Link to="/contact-us" className={contactActive}>
          Contact
        </Link>
      </Typography>
    </ul>
  );

  return (
    <Navbar className="sticky top-0 z-10 h-max max-w-full rounded-none py-4 px-4 lg:py-[20px] lg:px-[100px] 2xl:px-[300px]">
      <div className="flex items-center justify-between text-blue-gray-900">
        <Link to="#">
          <img src={logo} alt="logo" className="" />
        </Link>

        {/* middle */}
        <div className="mr-4 hidden lg:block">{navList}</div>

        {/* right */}
        <div className="flex items-center gap-4">
          {/* <div className="lg:flex gap-5 items-center hidden">
            <Link to="#" className="text-[14px] font-[500] text-yarpGray">
              Login
            </Link>
            <button className="px-[36px] py-2 rounded-[40px] h-[40px] bg-yarpOrange text-white text-[14px] font-[500] hover:scale-95 transition transform duration-300">
              Register
            </button>
          </div> */}
          <IconButton
            variant="text"
            className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
            ripple={false}
            onClick={() => setOpenNav(!openNav)}
          >
            {openNav ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                className="h-6 w-6"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            )}
          </IconButton>
        </div>
      </div>
      <MobileNav open={openNav}>
        {navList}
        {/* <div className="flex justify-between items-center">
          <Link to="#" className="text-[14px] font-[500] text-yarpGray">
            Login
          </Link>
          <button className="px-[36px] py-2 rounded-[40px] h-[40px] bg-yarpOrange text-white hover:scale-95 transition transform duration-300">
            Register
          </button>
        </div> */}
      </MobileNav>
    </Navbar>
  );
};

export default CustomNavbar;
