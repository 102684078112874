interface itemProps {
  icon: any;
}

const IconsBackground = ({ icon }: itemProps) => {
  return (
    <div className="w-[70px] h-[70px] rounded-[15px] bg-yarpOrange text-white flex justify-center items-center">
      {icon}
    </div>
  );
};

export default IconsBackground;
