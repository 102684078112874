import fleet1 from "../../../images/fleetFeature1.svg";
import fleet2 from "../../../images/fleetFeature2.svg";
import fleet3 from "../../../images/fleetFeature3.svg";
import fleet4 from "../../../images/fleetFeature4.svg";

const FeaturesComponent = () => {
  return (
    <>
      <div className="lg:mt-[127px] mt-[80px] lg:px-[100px] px-[20px]">
        <div className="lg:grid lg:grid-cols-2">
          <div>
            <img src={fleet1} alt="d1" />
          </div>
          <div className="flex flex-col justify-center">
            <p className="font-[600] lg:text-[24px] text-[20px] text-yarpGray">
              Enhanced Efficiency
            </p>
            <p className="font-[400] text-[14px] text-yarpGray mt-[20px]">
              Real-time tracking and route optimization lead to reduced idle
              time and fuel wastage.
            </p>
          </div>
        </div>

        <div className="lg:grid lg:grid-cols-2 mt-[70px]">
          <div className="flex flex-col justify-center">
            <p className="font-[600] lg:text-[24px] text-[20px] text-yarpGray">
              Fleet Monitoring
            </p>
            <p className="font-[400] text-[14px] text-yarpGray mt-[20px]">
              Monitor vehicle status, maintenance needs, and driver behavior to
              prolong vehicle lifespan.
            </p>
          </div>
          <div className="flex justify-center mt-5 lg:mt-0">
            <img src={fleet2} alt="d1" />
          </div>
        </div>

        <div className="lg:grid lg:grid-cols-2 mt-[70px]">
          <div>
            <img src={fleet3} alt="d1" />
          </div>
          <div className="flex flex-col justify-center mt-5 lg:mt-0">
            <p className="font-[600] lg:text-[24px] text-[20px] text-yarpGray">
              Data-Driven Decisions
            </p>
            <p className="font-[400] text-[14px] text-yarpGray mt-[20px]">
              Analyze data on routes, driver performance, and delivery patterns
              to make informed decisions.
            </p>
          </div>
        </div>

        <div className="lg:grid lg:grid-cols-2 mt-[70px]">
          <div className="flex flex-col justify-center">
            <p className="font-[600] lg:text-[24px] text-[20px] text-yarpGray">
              Optimised Resource Allocation
            </p>
            <p className="font-[400] text-[14px] text-yarpGray mt-[20px]">
              Assign deliveries based on driver availability and proximity for
              optimal resource utilization.
            </p>
          </div>
          <div className="flex justify-center">
            <img src={fleet4} alt="d1" />
          </div>
        </div>
      </div>
    </>
  );
};

export default FeaturesComponent;
