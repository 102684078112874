import CustomFooter from "../../../components/layout/custom-footer";
import CustomNavbar from "../../../components/layout/custom-navbar";
import DownloadNowComponent from "./download-component";
import FaqsComponent from "./faqs-component";
import TestimonyComponent from "./testimonyComponent";
import FrameI from "../../../images/FrameI.png";
import FrameII from "../../../images/FrameII.png";
import FrameIII from "../../../images/FrameIII.png";
import FrameIV from "../../../images/FrameIV.png";
import FrameV from "../../../images/FrameV.png";

const AIPoweredLogistics = () => {
  return (
    <>
      <div className="h-screen overflow-hidden">
        <CustomNavbar homeActive="active" />

        <div className="h-full overflow-y-auto">
          <div className="relative h-[640px]">
            <div className="absolute inset-0 bg-aiImg bg-cover bg-center">
              <div className="relative flex flex-col items-center justify-center h-full bg-black bg-opacity-50">
                <h1 className="text-white text-center text-[30px] md:text-[74px]">
                  AI-Powered Logistics <br /> Yarp Technologies' Smart Solutions
                </h1>
                <p className="md:w-[50%] text-[20px] font-poppins font-[300] text-white text-center mt-10">
                  Yarp Technologies harnesses the power of artificial
                  intelligence (AI) to revolutionize logistics operations. With
                  our smart solutions, Yarp Technologies enhances efficiency,
                  accuracy, and customer satisfaction in the logistics industry.
                  By leveraging AI technologies, Yarp Technologies is
                  transforming the way logistics is managed, delivering
                  intelligent and data-driven solutions to businesses.
                </p>
              </div>
            </div>
          </div>

          <div className="bg-secondaryBG py-[100px] lg:px-[100px] 2xl:px-[300px] px-5">
            <div className="lg:grid lg:grid-cols-2 lg:gap-x-[50px]">
              <div className="flex flex-col justify-center">
                <div className="mt-[20px]">
                  <p className="font-[600] text-[42px] leading-[63px] text-yarpGray">
                    Intelligent Route Optimization
                  </p>

                  <p className="mt-[12px] text-yarpGray font-[400] text-[20px]">
                    Yarp Technologies utilizes AI algorithms to optimize
                    delivery routes, considering various factors such as traffic
                    conditions, delivery volume, and time constraints. This
                    intelligent route optimization improves efficiency, reduces
                    travel time, and minimizes fuel consumption. By maximizing
                    resource utilization, Yarp Technologies helps businesses
                    streamline their logistics operations and achieve cost
                    savings.
                  </p>
                </div>
              </div>
              <div className="h-full hidden lg:block">
                <img className="" src={FrameI} alt="delivery" />
              </div>
            </div>
          </div>

          <div className="bg-secondaryBG py-[100px] lg:px-[100px] 2xl:px-[300px] px-5">
            <div className="lg:grid lg:grid-cols-2 lg:gap-x-[50px]">
              <div className="h-full hidden lg:block">
                <img className="" src={FrameII} alt="delivery" />
              </div>

              <div className="flex flex-col justify-center">
                <div className="mt-[20px]">
                  <p className="font-[600] text-[42px] leading-[63px] text-yarpGray">
                    Predictive Analytics for Demand Forecasting{" "}
                  </p>

                  <p className="mt-[12px] text-yarpGray font-[400] text-[20px]">
                    AI-powered predictive analytics enables Yarp Technologies to
                    forecast demand patterns accurately. By analyzing historical
                    data and market trends, Yarp Technologies helps businesses
                    anticipate fluctuations in demand, enabling them to
                    proactively adjust their inventory levels and allocate
                    resources efficiently. This predictive capability reduces
                    stockouts, optimizes inventory management, and improves
                    overall supply chain efficiency.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-secondaryBG py-[100px] lg:px-[100px] 2xl:px-[300px] px-5">
            <div className="lg:grid lg:grid-cols-2 lg:gap-x-[50px]">
              <div className="flex flex-col justify-center">
                <div className="mt-[20px]">
                  <p className="font-[600] text-[42px] leading-[63px] text-yarpGray">
                    Real-Time Tracking and Visibility{" "}
                  </p>

                  <p className="mt-[12px] text-yarpGray font-[400] text-[20px]">
                    Yarp Technologies employs AI-driven real-time tracking to
                    provide customers and businesses with complete visibility
                    throughout the delivery process. Through GPS-enabled
                    tracking systems, businesses can monitor shipments in
                    real-time, ensuring transparency and timely delivery. This
                    enhances customer satisfaction, reduces delivery
                    uncertainties, and builds trust in the logistics service.
                  </p>
                </div>
              </div>
              <div className="h-full hidden lg:block">
                <img className="" src={FrameIII} alt="delivery" />
              </div>
            </div>
          </div>

          <div className="bg-secondaryBG py-[100px] lg:px-[100px] 2xl:px-[300px] px-5">
            <div className="lg:grid lg:grid-cols-2 lg:gap-x-[50px]">
              <div className="h-full hidden lg:block">
                <img className="" src={FrameIV} alt="delivery" />
              </div>

              <div className="flex flex-col justify-center">
                <div className="mt-[20px]">
                  <p className="font-[600] text-[42px] leading-[63px] text-yarpGray">
                    Intelligent Fleet Management{" "}
                  </p>

                  <p className="mt-[12px] text-yarpGray font-[400] text-[20px]">
                    AI-powered predictive analytics enables Yarp Technologies to
                    forecast demand patterns accurately. By analyzing historical
                    data and market trends, Yarp Technologies helps businesses
                    anticipate fluctuations in demand, enabling them to
                    proactively adjust their inventory levels and allocate
                    resources efficiently. This predictive capability reduces
                    stockouts, optimizes inventory management, and improves
                    overall supply chain efficiency.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-secondaryBG py-[100px] lg:px-[100px] 2xl:px-[300px] px-5">
            <div className="lg:grid lg:grid-cols-2 lg:gap-x-[50px]">
              <div className="flex flex-col justify-center">
                <div className="mt-[20px]">
                  <p className="font-[600] text-[42px] leading-[63px] text-yarpGray">
                    Smart Customer Service:{" "}
                  </p>

                  <p className="mt-[12px] text-yarpGray font-[400] text-[20px]">
                    Yarp Technologies employs AI chatbots and virtual assistants
                    to provide instant and personalized customer support. These
                    intelligent systems can handle customer inquiries, track
                    order status, and provide relevant information, freeing up
                    human resources for more complex tasks. This seamless and
                    efficient customer service experience contributes to
                    customer satisfaction and loyalty. Yarp Technologies
                    leverages AI to deliver smart logistics solutions that
                    optimize operations and improve customer experiences. With
                    intelligent route optimization, predictive analytics,
                    real-time tracking, intelligent fleet management, and smart
                    customer service, Yarp Technologies is at the forefront of
                    AI-powered logistics. By harnessing the potential of AI,
                    Yarp Technologies continues to drive innovation and set new
                    standards in the logistics industry, helping businesses
                    thrive in an increasingly data-driven and efficient
                    ecosystem.
                  </p>
                </div>
              </div>
              <div className="h-full hidden lg:block">
                <img className="" src={FrameV} alt="delivery" />
              </div>
            </div>
          </div>

          <div className="lg:px-[100px] 2xl:px-[300px] px-5 mt-[146px] mb-[90px] lg:pb-[80px] bg-gradient-to-b from-white to-secondaryBG">
            <div className="w-full flex items-center justify-center">
              <h2 className="w-fit py-[4px] text-center border-[1px] border-[#FF6700] rounded-[40px] text-[#FF6700] px-[14px]">
                What they Say
              </h2>
            </div>
            <div className="flex justify-center w-full mt-[20px]">
              <p className="text-yarpGray lg:text-[42px] text-[28px] leading-[63px] font-[600] mt-[20px] lg:w-[833px] text-center">
                Users Testimonials
              </p>
            </div>
            <div className="flex justify-center w-full mt-[20px]">
              <p className="text-yarpGray lg:text-[20px] text-[14px] leading-[32px] font-[400] mt-[20px] lg:w-[833px] text-center">
                Discover What Our Customers Have to Say
              </p>
            </div>
            <TestimonyComponent />
          </div>

          <div className="lg:px-[100px] 2xl:px-[300px] px-5 mt-[146px] mb-[90px]">
            <div className="flex justify-center">
              <div className="w-[628px]">
                <p className="font-[600] text-[42px] leading-[63px] text-yarpGray text-center">
                  Download Now and Get Started Immediately!
                </p>
              </div>
            </div>
            <div className="mt-[32px]">
              <DownloadNowComponent />
            </div>
          </div>

          <div className="lg:px-[100px] 2xl:px-[300px] px-5 lg:mt-[146px] mb-[90px] lg:pb-[100px] bg-gradient-to-b from-white to-secondaryBG">
            <FaqsComponent />
          </div>

          {/* <div className="lg:px-[100px] px-5 lg:mt-[120px] mb-[90px] lg:pb-[55px]">
            <div className="flex justify-center">
              <PageTitileTag pageTitle="Subscribe" />
            </div>
            <div className="flex justify-center w-full mt-[20px]">
              <p className="text-yarpGray lg:text-[42px] text-[28px] lg:leading-[63px] font-[600] mt-[20px] lg:w-[833px] text-center">
                Subscribe to our newsletter
              </p>
            </div>
            <div className="flex justify-center w-full mt-[20px]">
              <p className="text-yarpGray lg:text-[20px] text-[14px] leading-[32px] font-[400] mt-[20px] lg:w-[833px] text-center">
                There are many variations of passages of Lorem Ipsum available
                but the majority have suffered alteration in some form.
              </p>
            </div>
            <div className="flex justify-center">
              <SubscribeComponent />
            </div>
          </div> */}

          {/* footer */}
          <div className="">
            <CustomFooter />
          </div>
        </div>
      </div>
    </>
  );
};

export default AIPoweredLogistics;
