const VideoComponent = () => {
  return (
    <>
      <div className="lg:mt-[87px] mt-[50px] flex mr-auto ml-auto lg:h-[557px] h-[300px] lg:w-[1000px] 2xl:w-[1200px]">
        <iframe
          className="rounded-[40px]"
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/1ZMqczJMEYs?si=Fk-4jbcYTmg4oDie&modestbranding=1&rel=0"
          title="Yarp Video"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
          allowFullScreen
        ></iframe>
      </div>
    </>
  );
};

export default VideoComponent;
