import deliveryMan from "../../../images/deliveryImg.png";
import PageTitileTag from "../../../components/general/page-title-tag";
import { Link } from "react-router-dom";
import { ArrowRightIcon } from "@heroicons/react/24/outline";

const WhyComponent2 = () => {
  return (
    <>
      <div className="lg:grid lg:grid-cols-2 lg:gap-x-[50px]">
        <div className="h-full hidden lg:block">
          <img className="" src={deliveryMan} alt="delivery" />
        </div>

        <div className="flex flex-col justify-center">
          <PageTitileTag pageTitle="Why Choose Yarp" />

          <div className="mt-[20px]">
            <p className="font-[600] text-[42px] leading-[63px] text-yarpGray">
              Streamlining Last-Mile Delivery
            </p>

            <p className="mt-[12px] text-yarpGray font-[400] text-[20px]">
              Yarp Technologies is revolutionizing last-mile delivery with
              advanced solutions that prioritize efficiency and enhance the
              customer experience. By optimizing routes, providing real-time
              tracking, dynamic delivery management, and continuous improvement
              based on feedback, Yarp Technologies ensures swift and reliable
              deliveries.
            </p>

            <div className="mt-[52px]">
              <Link
                to="/home/streamlining_last_mile_delivery"
                className="flex gap-3 text-yarpOrange items-center"
              >
                <p className="font-[600] text-[18px]">Learn More</p>
                <ArrowRightIcon className="h-5 w-5" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyComponent2;
