import { Menu, MenuHandler, MenuList } from "@material-tailwind/react";
import appleImg from "../../../images/apple-store-large.svg";
import playstoreImg from "../../../images/google-play-large.svg";
import truckImg from "../../../images/hometruck.png";
import { Link } from "react-router-dom";

const TopBanner = () => {
  return (
    <>
      <div className="py-4 pl-4 lg:pl-[100px] 2xl:pl-[300px] lg:py-[30px] lg:h-[700px] 2xl:h-[730px] bg-secondaryBG">
        <div className="lg:grid lg:grid-cols-2 lg:gap-x-[65px]">
          {/* left */}
          <div className="flex flex-col h-full lg:mt-[80px] mt-[20px]">
            <div className="lg:w-[564px]">
              <p className="font-[600] text-[40px] lg:text-[50px] text-yarpGray lg:leading-[74px] leading-[50px]">
                Moving your goods anywhere.
                <span className="text-yarpOrange">
                  {" "}
                  <br /> Fast and Easily
                </span>
              </p>

              <div className="lg:mt-[40px] mt-[30px]">
                <p className="lg:text-[20px] text-[18px] font-[400] lg:leading-[32px] leading-[28px] text-yarpGray">
                  Yarp effortlessly transports your goods anywhere, swiftly and
                  conveniently. Avoid the hassle of moving logistics. Choose
                  Yarp today!
                </p>
              </div>

              <div className="lg:mt-[70px] mt-[50px] flex gap-[18px] px-5 lg:px-0">
                {/* apple */}
                <Menu placement="bottom-start">
                  <MenuHandler className="outline-none">
                    <button className="h-[60px] w-[200px]">
                      <img src={appleImg} alt="apple" />
                    </button>
                  </MenuHandler>
                  <MenuList className="p-5 flex flex-col gap-y-3">
                    <Link
                      to="https://apps.apple.com/gh/app/yarp-move-goods-everywhere/id6468881078"
                      target="_blank"
                      className="w-full px-5 py-2 rounded-[40px] bg-yarpOrange text-white outline-none"
                    >
                      Download Yarp User
                    </Link>

                    <Link
                      to="https://apps.apple.com/gh/app/yarp-driver-drive-earn-more/id6468880490"
                      target="_blank"
                      className="w-full px-5 py-2 rounded-[40px] bg-yarpBlue text-white outline-none"
                    >
                      Download Yarp Driver
                    </Link>
                  </MenuList>
                </Menu>

                {/* android */}
                <Menu placement="bottom-start">
                  <MenuHandler className="outline-none">
                    <button className="h-[60px] w-[200px]">
                      <img src={playstoreImg} alt="play" />
                    </button>
                  </MenuHandler>
                  <MenuList className="p-5 flex flex-col gap-y-3">
                    <Link
                      to="https://play.google.com/store/apps/details?id=com.yarp.yarp"
                      target="_blank"
                      className="w-full px-5 py-2 rounded-[40px] bg-yarpOrange text-white outline-none"
                    >
                      Download Yarp User
                    </Link>

                    <Link
                      to="https://play.google.com/store/apps/details?id=com.yarp.yarpdriver"
                      target="_blank"
                      className="w-full px-5 py-2 rounded-[40px] bg-yarpBlue text-white outline-none"
                    >
                      Download Yarp Driver
                    </Link>
                  </MenuList>
                </Menu>
              </div>
            </div>
          </div>

          {/* right */}
          <div className="flex items-center justify-end relative mt-5 lg:mt-0">
            <img src={truckImg} alt="truck" className="w-full" />

            {/* <div className="absolute bottom-[-150px] hidden lg:block lg:mr-[64px]">
              <div className="p-[24px] bg-yarpOrange text-white rounded-[20px] flex gap-[14px]">
                <div>
                  <Typography className="font-[600] text-[14px] leading-[21px]">
                    Download Now and Get Started Immediately
                  </Typography>
                  <Typography className="font-[400] text-[14px] leading-[21px] mt-[4px]">
                    Yarp is now available on Playstor and app store
                  </Typography>
                </div>
                <div className="flex gap-[10px]">
                  <div>
                    <img src={appleWhite} alt="appleWhite" />
                    <img src={playWhite} alt="playWhite" className="mt-[8px]" />
                  </div>
                  <button className="h-[32px] w-[32px] rounded-full flex items-center justify-center bg-white">
                    <XMarkIcon className="h-5 w-5 text-yarpOrange" />
                  </button>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default TopBanner;
